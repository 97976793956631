import React, { Component } from "react";
import { List } from "../../../../components";
import "./searchesList.scss";
import moment from "moment";
class SearchesList extends Component {
  render() {
    const { UserList, handleUserClick, selectedUser } = this.props;
    return (
      <div>
        {UserList !== null ? (
          <List className="searches-list">
            {UserList.map((item, index) => (
              <List.ListItem
                onClick={() => handleUserClick(item)}
                key={item.CollaborationId}
                isSelected={
                  selectedUser
                    ? selectedUser.CollaborationId === item.CollaborationId
                    : null
                }
              >
                <div className="">
                  <div className={"defaultCompanyName"}>{item.Title}</div>
                  <div className="CompAddress">
                    {moment(item?.CreateDate).format("DD-MM-YYYY hh:mm")}
                  </div>
                </div>
              </List.ListItem>
            ))}
          </List>
        ) : null}
      </div>
    );
  }
}
export default SearchesList;
