import React, { Component } from "react";
import { connect } from "react-redux";
import "./selectedSearches.scss";
import moment from "moment";

class SelectedSearch extends Component {
  render() {
    const { labels, selectedUser } = this.props;
     return (
      <div className="selected-searches">
        {!selectedUser.UserId ? (
          <React.Fragment>{labels.NO_ITEM_LABEL}</React.Fragment>
        ) : (
          <div className="MsgWrapper">
            <div className="feedbackWrap">
              <div className="msgItemDiv">
                <div>
                  <label className="leftLabel">Collaboration Name:</label>
                  <label className="rightLabel">
                    {selectedUser?.Title || ""}
                  </label>
                </div>
                {selectedUser && selectedUser.Duration && (
                  <div>
                    <label className="leftLabel">Duration:</label>
                    <label className="rightLabel">
                      {selectedUser.Duration}
                    </label>
                  </div>
                )}
                {selectedUser && selectedUser.HourlyRate && (
                  <div>
                    <label className="leftLabel">Hourly Rate:</label>
                    <label className="rightLabel">
                      {selectedUser.HourlyRate}
                    </label>
                  </div>
                )}
                {selectedUser && selectedUser.StartDate && (
                  <div>
                    <label className="leftLabel">Start Date:</label>
                    <label className="rightLabel">
                      {moment(selectedUser?.StartDate).format(
                        "DD-MM-YYYY hh:mm"
                      )}
                    </label>
                  </div>
                )}
                {selectedUser && selectedUser.EndDate && (
                  <div>
                    <label className="leftLabel">End Date:</label>
                    <label className="rightLabel">
                      {moment(selectedUser?.EndDate).format("DD-MM-YYYY hh:mm")}
                    </label>
                  </div>
                )}
                {selectedUser && selectedUser.AcceptedDate && (
                  <div>
                    <label className="leftLabel">Accepted Date:</label>
                    <label className="rightLabel">
                      {moment(selectedUser?.AcceptedDate).format(
                        "DD-MM-YYYY hh:mm"
                      )}
                    </label>
                  </div>
                )}
                {selectedUser && selectedUser.Decription && (
                  <div>
                    <label className="leftLabel">Description:</label>
                    <label className="rightLabel">
                      {selectedUser.Decription}
                    </label>
                  </div>
                )}
                {selectedUser && selectedUser.CollaborationId && (
                  <div>
                    <label className="leftLabel">Collaboration ID:</label>
                    <label className="rightLabel">
                      {selectedUser.CollaborationId}
                    </label>
                  </div>
                )}
                {selectedUser && selectedUser.RequestId && (
                  <div>
                    <label className="leftLabel">Request ID:</label>
                    <label className="rightLabel">
                      {selectedUser.RequestId}
                    </label>
                  </div>
                )}
                {selectedUser && selectedUser.ResumeId && (
                  <div>
                    <label className="leftLabel">Resume ID:</label>
                    <label className="rightLabel">
                      {selectedUser.ResumeId}
                    </label>
                  </div>
                )}
                {selectedUser && selectedUser.UserId && (
                  <div>
                    <label className="leftLabel">User ID:</label>
                    <label className="rightLabel">{selectedUser.UserId}</label>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    labels: state.labels,
  };
}
export default connect(mapStateToProps)(SelectedSearch);
