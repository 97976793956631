import React,{Component} from 'react';
import {Column,Button,List, CustomInput} from "../../components";
import moment from "moment";
import "./feedback.scss";
import {  ReplyFeedback, getAdminFeedbacks, DeleteFeedbackAPI } from '../../Services/apiCalls';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import FeeedbackDetail from './components/FeeedbackDetail.js';
import {setNotificationAction,toggleLoadingAction } from '../../Redux/actions';

class Feedback extends Component{
    constructor(props){
        super(props);
        this.state={
            isCollapsed1:false,
            isCollapsed2:true,
            isCollapsed3:true,
            isUpdate:false,
            feedbackData:{
                FeedbackId: "",
                Title: -1,
                Img: "",
                Content: "",
                DateTime:"",
                UserId: "",
                ReplyTitle: "",
                ReplyDescription: ""
            },
            isReply: false,
            filteredFeedbacks: [],
            AllFeedbacks: [],
        }
    }
    componentDidMount=()=>{
        this.GetAdminFeedbacks();
    } 

    GetAdminFeedbacks = ()=>{
        this.props.toggleLoadingAction(true);
        getAdminFeedbacks()
        .then(res=>{
            this.setState({
                AllFeedbacks: res.items,
                filteredFeedbacks:res.items
            })
            this.props.toggleLoadingAction(false);
        })
        .catch(()=>{
            this.props.toggleLoadingAction(false);
        })
    }

    getImgSrc = src => {
        const { feedbackData } = this.state;
        this.setState({
            feedbackData: {
            ...feedbackData,
            Img: src
          }
        });
      };
    OnReplyAction = ()=>{
        const {feedbackData} = this.state;
        const {  Img, ReplyDescription, FeedbackId} = feedbackData;
        if(!ReplyDescription){
            const info = {
                message: this.props.labels.ADMIN_SYSTEM_LABELS_ENTER_HIGHLIGHTED_FIELDS,
                status: "error"
                };
                this.props.setNotificationAction(info);
                return null;
        }
        this.props.toggleLoadingAction(true);
            var model = {
                Img: Img,
                Content:  ReplyDescription,
                FeedbackId: FeedbackId, 
            };
            ReplyFeedback(model)
            .then(res=>{
               if(res.success){
                   const info = {
                    message: this.props.labels.ADMIN_REPLY_SUCCESS,
                    status: "success"
                    };
                    this.setState({
                        isUpdate: false,
                    })
                    this.props.toggleLoadingAction(false);
                    this.props.setNotificationAction(info);
               }else{
                const info = {
                    message: res.message,
                    status: "error"
                    };
                    this.props.toggleLoadingAction(false);
                    this.props.setNotificationAction(info);
               }
            })
    }

    DeleteFeedback = (selectedItem)=>{
        this.props.toggleLoadingAction(true);
        DeleteFeedbackAPI(selectedItem)
        .then(res=>{
            if(res.success){
                this.GetAdminFeedbacks();
                const info = {
                    message: this.props.labels.ADMIN_FEEDBACK_DELETE_SUCCESS,
                    status: "success"
                    };
                this.props.toggleLoadingAction(false);
                this.props.setNotificationAction(info);
            }else{
                const info = {
                    message: res.message,
                    status: "error"
                    };
                this.props.toggleLoadingAction(false);
                this.props.setNotificationAction(info);
            }
        })
    }

    searchFieldChange = e =>{
        const{
          name,
          value,
          type
        } = e.target;
        var data = this.state.AllFeedbacks;
        const filteredFeedbacks = data.filter(item=> item.UserName.toLowerCase().includes(value.toLowerCase()));
       
        this.setState({
          [name]:value,
          filteredFeedbacs: filteredFeedbacks
        });
      };
      handleAddNew = () => {
        this.setState({ 
            isCollapsed2: false,
            productsData: {
                Description: "",
                Id:  0,
                Name: "", 
                Price: 0,
                selectedType: "",
            },
      });
    }
    OnReplyForm=()=>{
        this.setState({isReply: true})
      }

      onFormFieldChange = e =>{
          const {feedbackData}= this.state;
        const{
          name,
          value,
          type
        } = e.target;      
        this.setState({
            feedbackData:{
                ...feedbackData,
                [name]:value,
            },
          
        });
      };
      handleDateChange=(date)=> {
        const {demoUserData}= this.state;
        
        this.setState({
          demoUserData:{
            ...demoUserData,
            ExpiryDate:date,
        },
        })
      }

      ListItemClick=(item)=>{
        const {feedbackData} = this.state;
        this.setState({
            isCollapsed2:false,
            isUpdate: true,
            feedbackData: {
                ...feedbackData,
                FeedbackId: item.FeedbackId,
                Title: item.Title,
                Img: item.Img,
                Content: item.Content,
                DateTime: item.DateTime,
                UserId: item.UserId,
                ReplyTitle: this.props.labels.ADMIN_REPLY_LABEL + " "+ item.Title
            },
            isReply: false,
            
        })
        
      }

      onFormSelectChange = (type,selectedOption) =>{
        const { productsData} = this.state;
        if(type == "types"){
            this.setState({
                productsData:{
                    ...productsData,
                    selectedType: selectedOption,
                }
            })
        }
        
    }
    render(){
        const {labels}= this.props;
        const{
            isHelpActive,
            isCollapsed1,
            isCollapsed2,
            filteredFeedbacks,
            Roles,
            FeedbackSearchText,
        } = this.state;
        return(
            <div className="feedback-wraper columns-wrapper">
             <Column collapse={isCollapsed1} className="col-1">
                <Column.Collapsed
                    text={labels.FEEDBACKS_MANAGEMENT}
                    onClick={() =>
                    this.setState({ isCollapsed1: false })
                    }
                    tooltipButton={labels.ADMIN_EXPAND}
                    isHelpActive={isHelpActive}
                    tooltipHelp={labels.ADMIN_EXPAND_BTN}
                    tooltipPlace="left"
                    testId={'column-1-expand'}
                />
                <Column.Head>
                    <div className="heading">
                        <p>
                            {labels.FEEDBACKS_MANAGEMENT}
                        </p>
                    </div>
                    <Button
                    onClick={() =>
                        this.setState({ isCollapsed1: true })
                    }
                    className="collapseBtn"
                    tooltipButton={labels.ADMIN_COLLAPSE}
                    tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
                    tooltipPlace="left"
                    isHelpActive={isHelpActive}
                    testId={'column-1-collapse'}
                    />
                </Column.Head>
                <Column.Body>
                    <div className="admin-management-role-col1">
                        <div className={`${!FeedbackSearchText  ? "inValid " : " "} input-field`}>
                            <h6>{labels.FEEDBACK_SEARCH}</h6>
                            <div>
                                <input
                                    name={"FeedbackSearchText"}
                                    type={"text"}
                                    value={FeedbackSearchText||""}
                                    onChange={e => this.searchFieldChange(e)}
                                    test-data-id={"admin-management-role-input"}
                                    placeholder={labels.FEEDBACK_SEARCH}
                                />
                            </div>
                            
                        </div>
                        {filteredFeedbacks && filteredFeedbacks.length > 0 ?
                            <div className="list-wrapper">                
                                    {filteredFeedbacks.map((item, index)=>
                                        <div test-data-id={`roles-list-item`} 
                                        className={`list-item`} 
                                        key={index}
                                        onClick = {() =>this.ListItemClick(item)}
                                        >
                                            <div
                                                className="list_item_title"> 
                                                <span>{item.Title}</span>
                                            </div>
                                            <button  test-data-id={`delete-role-btn`} type="button" className="delete-btn" onClick={()=>this.DeleteFeedback(item)} />
                                        </div>
                                    )}                        
                            </div>
                            :
                            <div className ="no-item-label"><label >
                                {labels.ADMIN_NO_ITEM_TO_DISPLAY}
                            </label>
                        </div>} 
                    </div>
                </Column.Body>
             </Column>

             <Column collapse={isCollapsed2} className="col-1">
                <Column.Collapsed
                    text={labels.FEEDBACK_DETAILS_LABEL}
                    onClick={() =>
                    this.setState({ isCollapsed2: false })
                    }
                    tooltipButton={labels.ADMIN_EXPAND}
                    isHelpActive={isHelpActive}
                    tooltipHelp={labels.ADMIN_EXPAND_BTN}
                    tooltipPlace="left"
                    testId={'column-2-expand'}
                />
                <Column.Head>
                    <div className="heading">
                        <p>
                            {labels.FEEDBACK_DETAILS_LABEL}
                        </p>
                    </div>
                    <Button
                    onClick={() =>
                        this.setState({ isCollapsed2: true })
                    }
                    className="collapseBtn"
                    tooltipButton={labels.ADMIN_COLLAPSE}
                    tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
                    tooltipPlace="left"
                    isHelpActive={isHelpActive}
                    testId={'column-1-collapse'}
                    />
                </Column.Head>
                <Column.Body>
                <FeeedbackDetail 
                    Roles = {Roles}
                    labels={this.props.labels}
                    productsData={this.state.productsData}
                    isUpdate={this.state.isUpdate}
                    onFormFieldChange={this.onFormFieldChange}
                    OnReplyAction={this.OnReplyAction}
                    OnReplyForm={this.OnReplyForm}
                    onFormSelectChange={this.onFormSelectChange}
                    getImgSrc={this.getImgSrc}
                    feedbackData = {this.state.feedbackData}
                    productTypes = {this.state.productTypes}
                    isReply = {this.state.isReply}
                    /> 
                </Column.Body>
             </Column>
             
        </div>
        )
    }
}
function mapStateToProps(state){
    return{
      user:state.user,
      isLoading:state.isLoading,
      labels: state.labels
    };
  }
  
  const actions ={
    setNotificationAction,
    toggleLoadingAction
  }
  
export default connect(mapStateToProps,actions)(withRouter(Feedback));