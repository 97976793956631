import React, { Component } from "react";
import { connect } from "react-redux";
import "./MemberDetail.scss";

class UserDetails extends Component {
    render() {
        const { labels, selectedMember, getResumeDetail } = this.props;
        return (
            <div className="user-detail-component">
                {!selectedMember.UserId ? (
                    <React.Fragment>{labels.NO_ITEM_LABEL}</React.Fragment>
                ) : (
                    <div className="MsgWrapper">
                        <div className="companyBigIcon">
                            <div className={`dashItemImg no-dp"}`}>
                                <img
                                    className="roundeImg"
                                    src={selectedMember.ProfilePicture}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="titleLabel">{selectedMember.UserFirstname + " " + (selectedMember.UserLastname ? selectedMember.UserLastname : "")}</div>
                        <div className="feedbackWrap">
                            <div className="msgItemDiv">
                                <div>
                                    <label className="leftLabel">
                                        {labels.TYPE_LABEL}
                                    </label>
                                    <label className="rightLabel">
                                        {selectedMember.IsFreelancer ? labels.IPRO_NAVIGATION_ROLE_LABEL : labels.SEARCHER_NAVIGATION_ROLE_LABEL}
                                    </label>
                                </div>
                                {selectedMember &&
                                    selectedMember.UserEmail && (
                                        <div>
                                            <label className="leftLabel">
                                                {labels.EMAIL_LABEL}
                                            </label>
                                            <label className="rightLabel">
                                                {selectedMember.UserEmail}
                                            </label>
                                        </div>
                                    )}
                                {selectedMember &&
                                    selectedMember.CountryName && (
                                        <div>
                                            <label className="leftLabel">
                                                {labels.iProOpportunityNewDetailLblCountry}
                                            </label>
                                            <label className="rightLabel">
                                                {selectedMember.CountryName}
                                            </label>
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        labels: state.labels
    };
}
export default connect(mapStateToProps)(UserDetails);