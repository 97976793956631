import React from "react";
import {
  getAllLanguageApi,
  getAllLookupsApi,
  getSelectedLookupsApi,
  GetLookupsByType,
  updateLookupItemApi,
  DeleteLookupItemApi,
  getAllLookupTypeStatusApi,
  getAllCandidateLookupTypeApi,
} from "../../../Services/apiCalls";
import loadingIcon from "../../../Assests/Images/loading.gif";
import { Button, Column } from "../../../components";

class LookupWorkPlace extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditLookup: false,
      LanguageList: [],
      LookupsList: [],
      selectedLookup: {},
      LookupsStatusList: [],
      searchKey: "",
      SelectedLookupList: [],
      filteredLookupList: [],
      currentLookup: [
        {
          LookupTypesId: "",
          LookupName: "",
        },
      ],
      currentLanguage: [
        {
          LanguageId: "",
          LanguageValue: "",
        },
      ],
      currentSelectedLookup: [
        {
          LanguageId: "",
          LanguageValue: "",
        },
      ],
      currentSelectedLookupStatus: [
        {
          Value: "",
          Name: "",
        },
      ],
    };
  }
  componentDidMount() {
    this.props.toggleLoadingAction(true);
    this.GetAllLanguages();
    this.GetAllLookups();
  }

  GetAllLanguages = () => {
    getAllLanguageApi()
      .then((res) => {
        let newLanguageList = res.items.map((item) => {
          return {
            ...item,
            value: item.LanguageId,
            label: item.LanguageValue,
          };
        });
        this.setState({
          LanguageList: newLanguageList,
          currentLanguage: newLanguageList[0],
        });
        // this.onFormLanguageSelectChange(newLanguageList[0])

        //for Lookup call
      })
      .catch((err) => console.log(err));
  };

  GetAllLookups = () => {
    getAllLookupsApi()
      .then((res) => {
        let newLookupsList = res.items.map((item) => {
          return {
            ...item,
            value: item.LookupTypesId,
            label: item.LookupName,
          };
        });
        this.setState({
          LookupsList: newLookupsList,
          currentLookup: [
            {
              LookupTypesId: newLookupsList[0].LookupTypesId,
              LookupName: newLookupsList[0].LookupName,
            },
          ],
        });
        this.GetAllLookupStatus();
      })
      .catch((err) => console.log(err));
  };

  GetAllLookupStatus = () => {
    getAllLookupTypeStatusApi()
      .then((res) => {
        let newList = res.items.map((item) => {
          return {
            ...item,
            value: item.Value,
            label: item.Name,
          };
        });
        this.setState({
          LookupsStatusList: newList,
          currentSelectedLookupStatus: newList[0],
        });
        let nameofLookup = this.state.currentLookup[0].LookupName.slice(0, -1);
        this.ApprovedlookUpApiCall(nameofLookup);
        this.props.toggleLoadingAction(false);
        this.onFormLookupStatusChange(newList[0]);
      })
      .catch((err) => console.log(err));
  };

  onFormLanguageSelectChange = (selectedOption) => {
    const { name, value, type } = selectedOption.target;
    const filteredSubModules = this.state.LanguageList.filter(
      (item) => item.LanguageId == selectedOption.target.value
    );
    this.setState({
      selectedLanguage: value,
      filteredSubModules,
    });
  };

  onFormLookupSelectChange = (selectedOption) => {
    if (this.state.LanguageList.length == 0) {
      const info = {
        message: this.props.labels.ADMIN_SELECT_LANGUAGE_MSG,
        status: "error",
      };
      this.props.setNotificationAction(info);
      return false;
    }
    const { name, value, type } = selectedOption.target;
    const filteredSubModules = this.state.LookupsList.filter(
      (item) => item.LookupTypesId == selectedOption.target.value
    );
    let Selectedlookup = [
      {
        LookupTypesId: this.state.LookupsList[
          selectedOption.target.options.selectedIndex
        ].LookupTypesId,
        LookupName: this.state.LookupsList[
          selectedOption.target.options.selectedIndex
        ].LookupName,
      },
    ];
    this.setState({
      searchKey: "",
      filteredLookupList: [],
      selectedLookupType: value,
      filteredSubModules,
      currentLookup: Selectedlookup,
      SelectedLookupList: [],
      selectedLookupStatusType: this.state.LookupsStatusList,
    });
    this.props.toggleLoadingAction(true);
    this.ApprovedlookUpApiCall(Selectedlookup[0].LookupName.slice(0, -1));
    this.props.toggleLoadingAction(false);
  };

  onFormLookupStatusChange = (selectedOption) => {
    if (this.state.LookupsList.length == 0) {
      const info = {
        message: this.props.labels.ADMIN_SELECT_LOOKUP_MSG,
        status: "error",
      };
      this.props.setNotificationAction(info);
      return false;
    }
    // const { value } = selectedOption?.target;
    const filteredSubModules = this?.state?.LookupsStatusList.filter(
      (item) => item?.Value == selectedOption?.target?.value
    );
    this.setState({
      searchKey: "",
      filteredLookupList: [],
      SelectedLookupList: [],
      selectedLookupStatusType: selectedOption?.target?.value,
      filteredSubModules,
    });
    const lookupStatusName = filteredSubModules[0]?.Name;
    const lookupName = this.state.currentLookup[0]?.LookupName?.slice(0, -1);
    if (lookupStatusName == "Approved") {
      this.props.toggleLoadingAction(true);
      this.ApprovedlookUpApiCall(lookupName);
      this.props.toggleLoadingAction(false);
    } else {
      this.props.toggleLoadingAction(true);
      getAllCandidateLookupTypeApi(lookupName)
        .then((res) => {
          this.props.toggleLoadingAction(false);
          if (res.items == undefined || res.items.length == 0) {
            const info = {
              message: this.props.labels.ADMIN_NO_RECORD_FOUND_MSG,
              status: "error",
            };
            this.props.setNotificationAction(info);
            return false;
          }
          const newList = this.lookUpData(res, lookupName);
          this.setState({
            SelectedLookupList: newList,
          });
        })
        .catch((err) => console.log(err));
    }
  };

  ApprovedlookUpApiCall(lookupName) {
    console.log("The lookup name is = ",lookupName)
    if (lookupName == "Industrie" || lookupName == "Industerie") {
      lookupName = "Industry";
    }
    if (lookupName == "Countrie" || lookupName == " Counterie") {
      lookupName = "Country";
    }
    getSelectedLookupsApi(lookupName)
      .then((res) => {
        if (res.items == undefined) {
          const info = {
            message: this.props.labels.ADMIN_NO_RECORD_FOUND_MSG,
            status: "error",
          };
          this.props.setNotificationAction(info);
          return false;
        }
        const newList = this.lookUpData(res, lookupName);
        this.setState({
          SelectedLookupList: newList,
          filteredLookupList: newList,
        });
      })
      .catch((err) => console.log(err));
  }

  lookUpData(data, lookupName) {
    const newList = data.items.map((item) => {
      if (lookupName == "Profile") {
        const newItem = {
          Id: item.ProfileId,
          Title: item.ProfileValue,
        };
        return newItem;
      }

      if (lookupName == "Skill") {
        const newItem = {
          Id: item.SkillId,
          Title: item.SkillValue,
        };
        return newItem;
      }

      if (lookupName == "Keyword") {
        const newItem = {
          Id: item.KeywordId,
          Title: item.KeywordValue,
        };
        return newItem;
      }
      if (lookupName == "Country") {
        const newItem = {
          Id: item.CountryId,
          Title: item.CountryName,
        };
        return newItem;
      }
      if (lookupName == "Certification") {
        const newItem = {
          Id: item.CertificationId,
          Title: item.CertificationValue,
        };
        return newItem;
      }

      if (lookupName == "Language") {
        const newItem = {
          Id: item.LanguageId,
          Title: item.LanguageValue,
        };
        return newItem;
      }
      if (lookupName == "Industry") {
        const newItem = {
          Id: item.IndustryId,
          Title: item.IndustryValue,
        };
        return newItem;
      }
    });
    return newList;
  }

  handleSearchChange = (e) => {
    let filteredlookups = [];
    const { value } = e.target;
    const { SelectedLookupList } = this.state;
    if (SelectedLookupList?.length > 0) {
      filteredlookups = this?.state?.SelectedLookupList?.filter((wp) =>
        wp?.Title?.toLowerCase()?.includes(value?.toLowerCase())
      );
    }
    this.setState({
      searchKey: e.target.value,
      filteredLookupList: filteredlookups,
    });
  };

  handleSearchBlurChange = (e) => {
    const value = this.state.searchKey;
    const { SelectedLookupList } = this.state;
    let newLookupList = SelectedLookupList;
    if (SelectedLookupList.length > 0) {
      const filteredlookups = this.state.SelectedLookupList.filter((wp) =>
        wp.Title.toLowerCase().includes(value.toLowerCase())
      );
    }
    //for api call
    let lookupType = this.state.currentLookup[0].LookupName.slice(0, -1);
    if (!value) {
      const info = {
        message: this.props.labels.ADMIN_SYSTEM_LABELS_ENTER_HIGHLIGHTED_FIELDS,
        status: "error",
      };
      this.props.setNotificationAction(info);
      return null;
    }
    lookupType = lookupType.replace("ie", "y");
    const ItemName = value;
    this.props.toggleLoadingAction(true);
    GetLookupsByType(lookupType, ItemName)
      .then((res) => {
        console.log("res", res);

        if (res?.items?.length > 0) {
          let lookupItem = [];
          res.items.filter((lookup) => {
            if (lookup.ProfileValue === ItemName) {
              const item = {
                Id: lookup.ProfileId,
                Title: lookup.ProfileValue,
              };
              lookupItem.push(item);
            }
          });
          if (lookupItem !== 0) newLookupList.push(lookupItem[0]);
          this.props.toggleLoadingAction(false);
          const newList = this.lookUpData(res, lookupType);
          this.setState({
            searchKey: ItemName,
            filteredLookupList: newList,
            SelectedLookupList: newLookupList,
          });
          this.props.toggleLoadingAction(false);
          const info = {
            message: this.props.labels.ADMIN_LOOKUP_ADDED,
            status: "success",
          };
          this.props.setNotificationAction(info);
          return;
        } else {
          const info = {
            message: "Lookup already exist try again",
            status: "error",
          };
          this.props.setNotificationAction(info);
          this.props.toggleLoadingAction(false);
        }
      })
      .catch((err) => console.log(err));
  };

  lookupInputChange = (e) => {
    const { selectedLookup } = this.state;
    const { value } = e.target;
    e.Title = value;
    this.setState({
      selectedLookup: { ...selectedLookup, Title: value },
    });
  };

  EnableLookupUpdate = (e) => {
    const { filteredLookupList } = this.state;
    let newList = filteredLookupList.map((item) => {
      item.Id == e.Id
        ? (item.isLookupEdit = true)
        : (item.isLookupEdit = false);
      return item;
    });
    this.setState({
      filteredLookupList: newList,
      selectedLookup: e,
      isEditLookup: true,
    });
  };

  updateLookup = () => {
    const { filteredLookupList, selectedLookup } = this.state;
    //for api call
    let lookupType = this.state.currentLookup[0].LookupName.slice(0, -1);
    lookupType = lookupType.replace("ie", "y");
    if (!selectedLookup.Id) {
      const info = {
        message: this.props.labels.ADMIN_SYSTEM_LABELS_ENTER_HIGHLIGHTED_FIELDS,
        status: "error",
      };
      this.props.setNotificationAction(info);
      return;
    }
    this.props.toggleLoadingAction(true);
    updateLookupItemApi(
      lookupType,
      selectedLookup.Id,
      selectedLookup.Title,
      this.state.searchKey || lookupType
    )
      .then((res) => {
        this.props.toggleLoadingAction(false);
        if (res.success) {
          let newList = filteredLookupList.map((item) => {
            if (item.Id == selectedLookup.Id) {
              item.Title = selectedLookup.Title;
            }
            return item;
          });
          this.setState({
            filteredLookupList: newList,
          });

          const info = {
            message: "Lookup updated successfully",
            status: "success",
          };
          this.props.setNotificationAction(info);
          return;
        }
      })
      .catch((err) => console.log(err));
  };

  deleteRow = (lookupItem) => {
    const lookupType = this.state.currentLookup[0].LookupName.slice(0, -1);
    const lookupitemId = lookupItem.Id;
    const ItemName = this.state.searchKey;

    if (this.state.isEditLookup) {
      this.setState({
        isEditLookup: false,
      });
    } else {
      this.props.toggleLoadingAction(true);
      DeleteLookupItemApi(lookupType, lookupitemId, lookupItem?.Title)
        .then((res) => {
          if (res.success) {
            const info = {
              message: "Lookup deleted successfully",
              status: "success",
            };
            this.props.setNotificationAction(info);
            const newList = this.state.filteredLookupList.filter(
              (l) => l.Id != lookupitemId
            );
            this.setState({
              searchKey: ItemName,
              filteredLookupList: newList,
              isEditLookup: false,
            });
          } else {
            const info = {
              message: `${res.items}`,
              status: "error",
            };
            this.props.setNotificationAction(info);
          }
          // if (res.success == false) {
          //   this.props.toggleLoadingAction(false);
          //   const info = {
          //     message: `${res.items}`,
          //     status: "error",
          //   };
          //   this.props.setNotificationAction(info);
          //   return false;
          // }
          this.props.toggleLoadingAction(false);
        })
        .catch((err) => {
          this.props.toggleLoadingAction(false);
        });
    }
  };
  render() {
    const { labels } = this.props;
    const { LookupTypesId, LookupName } = this.state.currentLookup;
    const { selectedLookup, isEditLookup } = this.state;

    return (
      <div className="lookupForm">
        <div className="input-field setHeight50p">
          <h6>{labels.ADMIN_LANGUAGES}</h6>
          <select
            name="LanguageId"
            test-data-id={"LanguageId"}
            value={
              (this.state.selectedLanguage && this.state.selectedLanguage) || ""
            }
            onChange={(selectedOption) =>
              this.onFormLanguageSelectChange(selectedOption)
            }
          >
            {this.state.LanguageList.map((item) => (
              <option value={item.LanguageId} key={item.LanguageId}>
                {item.LanguageValue}
              </option>
            ))}
          </select>
        </div>
        <div className="input-field setHeight50p">
          <h6>{labels.ADMIN_LOOKUPs_LABEL}</h6>
          <select
            name="LookuptypeId"
            test-data-id={"LookuptypeId"}
            value={
              (this.state.selectedLookupType &&
                this.state.selectedLookupType) ||
              ""
            }
            onChange={(selectedOption) =>
              this.onFormLookupSelectChange(selectedOption)
            }
          >
            {this.state.LookupsList.map((item) => (
              <option value={item.LookupTypesId} key={item.LookupTypesId}>
                {item.LookupName}
              </option>
            ))}
          </select>
        </div>

        <div className="input-field setHeight50p">
          <h6>{labels.ADMIN_LOOKUP_STATUS_TYPES}</h6>
          <select
            test-data-id={"LookuptypeStatusId"}
            value={
              (this?.state?.selectedLookupStatusType &&
                this?.state?.selectedLookupStatusType) ||
              ""
            }
            onChange={(selectedOption) =>
              this.onFormLookupStatusChange(selectedOption)
            }
          >
            {this.state.LookupsStatusList.map((item) => (
              <option value={item.Value} key={item.Value}>
                {item.Name}
              </option>
            ))}
          </select>
        </div>

        <Column className="CustomSearch">
          <Column.Head>
            <div
              className={`${
                !this.state.searchKey ? "inValid " : " "
              } input-field searchfield setHeight50p`}
            >
              <h6>{labels.ADMIN_SEARCH_LABEL}</h6>
              <div>
                <input
                  name={"SearchLookUps"}
                  type={"text"}
                  value={this?.state?.searchKey}
                  onChange={this.handleSearchChange}
                />
              </div>
            </div>
            <Button className="AddBtn" onClick={this.handleSearchBlurChange} />
          </Column.Head>
        </Column>

        {this.state.filteredLookupList &&
        this.state.filteredLookupList.length > 0 ? (
          <div className="list-wrapper">
            {this.state.filteredLookupList &&
              this.state.filteredLookupList.length > 0 &&
              this.state.filteredLookupList.map((item) => (
                <div
                  test-data-id={`workplace-list-item-${item.Title.replace(
                    " ",
                    "-"
                  ).toLowerCase()}`}
                  className={`list-item ${item.isActive ? "active" : ""}`}
                  key={item.Id}
                >
                  {isEditLookup && item.isLookupEdit ? (
                    <input
                      name={"value"}
                      type={"text"}
                      value={selectedLookup.Title || ""}
                      onChange={(e) => this.lookupInputChange(e)}
                      autoFocus={true}
                      className={`editLookupEnabled`}
                    />
                  ) : (
                    <div className="list_item_title">
                      <span>{item.Title}</span>
                    </div>
                  )}

                  <div className="action-btns">
                    {isEditLookup && item.isLookupEdit ? (
                      <button
                        test-data-id={`delete-${item.Title}`}
                        type="button"
                        className="SaveCloudBtn"
                        onClick={(e) => this.updateLookup(item)}
                        id={item.Id}
                        name={item.Title}
                      />
                    ) : (
                      <button
                        test-data-id={`delete-${item.Title}`}
                        type="button"
                        className="edit-btn"
                        onClick={(e) => this.EnableLookupUpdate(item)}
                        id={item.Id}
                        name={item.Title}
                      />
                    )}

                    <button
                      test-data-id={`delete-${item.Title}`}
                      type="button"
                      className="delete-btn"
                      onClick={(e) => this.deleteRow(item)}
                      id={item.Id}
                      name={item.Title}
                    />
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className="no-item-label" style={{ margin: "10px" }}>
            <label>{labels.ADMIN_NO_ITEM_TO_DISPLAY}</label>
          </div>
        )}
      </div>
    );
  }
}

export default LookupWorkPlace;
