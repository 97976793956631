import React, { Component } from "react";
import { List } from "../../../components";
import "./searchesList.scss";
import moment from "moment";
class SearchesList extends Component {
  render() {
    const { data, handleClick, selectedLog } = this.props;

    return (
      <div>
        {data !== null ? (
          <List className="searches-list">
            {data?.map((item, index) => (
              <List.ListItem
                key={index}
                onClick={() => handleClick(item)}
                isSelected={
                  selectedLog
                    ? selectedLog?.Id === item?.Id
                    : null
                }
              >
                <div className="">
                  <div className={"defaultCompanyName"}>
                    {item?.Message || ""}
                  </div>
                  <div className="CompAddress">
                    Date:{" "}
                    {moment(item?.CreateDate).format("YYYY-MM-DD hh:mm:ss")}
                  </div>
                </div>
              </List.ListItem>
            ))}
          </List>
        ) : null}
      </div>
    );
  }
}
export default SearchesList;
