import React from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { publicRoutes, privateRoutes } from "./Routes";
import { connect } from "react-redux";
import { PageWrapper, Header, Notification } from "../components";

class Routing extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <PublicRoute
            exact
            name={privateRoutes.dashboard.name}
            path={publicRoutes.default.path}
            component={publicRoutes.default.component}
            {...this.props}
          />

          <PublicRoute
            name={publicRoutes.emailverification.name}
            path={`${publicRoutes.emailverification.path}/:token`}
            component={publicRoutes.emailverification.component}
            {...this.props}
          />
          <PrivateRoute
            name={privateRoutes.dashboard.name}
            path={privateRoutes.dashboard.path}
            component={privateRoutes.dashboard.component}
            {...this.props}
          />
          <PrivateRoute
            name={privateRoutes.SystemLabels.name}
            path={privateRoutes.SystemLabels.path}
            component={privateRoutes.SystemLabels.component}
            {...this.props}
          />
          <PrivateRoute
            name={privateRoutes.features.name}
            path={privateRoutes.features.path}
            component={privateRoutes.features.component}
            {...this.props}
          />
          <PrivateRoute
            name={privateRoutes.lookups.name}
            path={privateRoutes.lookups.path}
            component={privateRoutes.lookups.component}
            {...this.props}
          />
          <PrivateRoute
            name={privateRoutes.LookupCorrelation.name}
            path={privateRoutes.LookupCorrelation.path}
            component={privateRoutes.LookupCorrelation.component}
            {...this.props}
          />
          <PrivateRoute
            name={privateRoutes.AdminManagementRole.name}
            path={privateRoutes.AdminManagementRole.path}
            component={privateRoutes.AdminManagementRole.component}
            {...this.props}
          />
          <PrivateRoute
            name={privateRoutes.AdminManagementAdmin.name}
            path={privateRoutes.AdminManagementAdmin.path}
            component={privateRoutes.AdminManagementAdmin.component}
            {...this.props}
          />
          <PrivateRoute
            name={privateRoutes.AdminFeaturesManagement.name}
            path={privateRoutes.AdminFeaturesManagement.path}
            component={privateRoutes.AdminFeaturesManagement.component}
            {...this.props}
          />
          <PrivateRoute
            name={privateRoutes.AdminActivityManagement.name}
            path={privateRoutes.AdminActivityManagement.path}
            component={privateRoutes.AdminActivityManagement.component}
            {...this.props}
          />
          <PrivateRoute
            name={privateRoutes.configurationVersion.name}
            path={privateRoutes.configurationVersion.path}
            component={privateRoutes.configurationVersion.component}
            {...this.props}
          />
          <PrivateRoute
            name={privateRoutes.ConfigurationTermsAndConditions.name}
            path={privateRoutes.ConfigurationTermsAndConditions.path}
            component={privateRoutes.ConfigurationTermsAndConditions.component}
            {...this.props}
          />
          <PrivateRoute
            name={privateRoutes.ConfigurationLegalNotice.name}
            path={privateRoutes.ConfigurationLegalNotice.path}
            component={privateRoutes.ConfigurationLegalNotice.component}
            {...this.props}
          />
          <PrivateRoute
            name={privateRoutes.ConfigurationPrivacyPolicy.name}
            path={privateRoutes.ConfigurationPrivacyPolicy.path}
            component={privateRoutes.ConfigurationPrivacyPolicy.component}
            {...this.props}
          />
          <PrivateRoute
            name={privateRoutes.ConfigurationCookieAgreement.name}
            path={privateRoutes.ConfigurationCookieAgreement.path}
            component={privateRoutes.ConfigurationCookieAgreement.component}
            {...this.props}
          />
          <PrivateRoute
            name={privateRoutes.MyAccount.name}
            path={privateRoutes.MyAccount.path}
            component={privateRoutes.MyAccount.component}
            {...this.props}
          />
          <PrivateRoute
            name={privateRoutes.BusinessUsers.name}
            path={privateRoutes.BusinessUsers.path}
            component={privateRoutes.BusinessUsers.component}
            {...this.props}
          />
          <PrivateRoute
            name={privateRoutes.Products.name}
            path={privateRoutes.Products.path}
            component={privateRoutes.Products.component}
            {...this.props}
          />
          <PrivateRoute
            name={privateRoutes.Feedback.name}
            path={privateRoutes.Feedback.path}
            component={privateRoutes.Feedback.component}
            {...this.props}
          />
          <PrivateRoute
            name={privateRoutes.Email.name}
            path={privateRoutes.Email.path}
            component={privateRoutes.Email.component}
            {...this.props}
          />
          <PrivateRoute
            name={privateRoutes.Statistics.name}
            path={privateRoutes.Statistics.path}
            component={privateRoutes.Statistics.component}
            {...this.props}
          />
          <PrivateRoute
            name={privateRoutes.Members.name}
            path={privateRoutes.Members.path}
            component={privateRoutes.Members.component}
            {...this.props}
          />
          <PrivateRoute
            name={privateRoutes.Reports.search.name}
            path={privateRoutes.Reports.search.path}
            component={privateRoutes.Reports.search.component}
            {...this.props}
          />
          <PrivateRoute
            name={privateRoutes.Reports.opportunity.name}
            path={privateRoutes.Reports.opportunity.path}
            component={privateRoutes.Reports.opportunity.component}
            {...this.props}
          />
          <PrivateRoute
            name={privateRoutes.Reports.collaboration.name}
            path={privateRoutes.Reports.collaboration.path}
            component={privateRoutes.Reports.collaboration.component}
            {...this.props}
          />
          <PrivateRoute
            name={privateRoutes.Reports.lookUp.name}
            path={privateRoutes.Reports.lookUp.path}
            component={privateRoutes.Reports.lookUp.component}
            {...this.props}
          />
          <PrivateRoute
            name={privateRoutes.EmailTemplates.name}
            path={privateRoutes.EmailTemplates.path}
            component={privateRoutes.EmailTemplates.component}
            {...this.props}
          />
           <PrivateRoute
            name={privateRoutes.ErrorLogs.name}
            path={privateRoutes.ErrorLogs.path}
            component={privateRoutes.ErrorLogs.component}
            {...this.props}
          />
          <Route
            path={publicRoutes.noMatch.path}
            component={publicRoutes.noMatch.component}
          />
        </Switch>
      </Router>
    );
  }
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user } = rest;
  const { IsAuthenticated } = user;
  return (
    <Route
      {...rest}
      render={
        (props) =>
          IsAuthenticated ? (
            <PageWrapper>
              <Component {...props} />
            </PageWrapper>
          ) : (
            ""
          )
        // <Redirect to={publicRoutes.default.path} />
      }
    />
  );
};

const PublicRoute = ({ component: Component, ...rest }) => {
  const { user, notification } = rest;
  const { IsAuthenticated } = user;
  const { message, status } = notification;
  return (
    <Route
      {...rest}
      render={(props) =>
        !IsAuthenticated ? (
          <div className="page-wrapper">
            {message && <Notification message={message} status={status} />}
            <Component {...props} />
          </div>
        ) : (
          <Redirect to={privateRoutes.SystemLabels.path} />
        )
      }
    />
  );
};

function mapStateToProps(state) {
  return {
    user: state.user,
    notification: state.notification,
  };
}

export default connect(mapStateToProps)(Routing);
