import React, { Component } from "react";
import { connect } from "react-redux";
import "./selectedLogs.scss";
import moment from "moment";

class SelectedLog extends Component {
  render() {
    const { labels, selectedLog } = this.props;
    return (
      <div className="selected-logs">
        {!selectedLog?.Id ? (
          <React.Fragment>{labels.NO_ITEM_LABEL}</React.Fragment>
        ) : (
          <div className="MsgWrapper">
            <div className="feedbackWrap">
              <div className="msgItemDiv">
                <div>
                  <label className="leftLabel">Origin:</label>
                  <label className="rightLabel">
                    {selectedLog?.Origin || ""}
                  </label>
                </div>
                {selectedLog && selectedLog?.Message && (
                  <div>
                    <label className="leftLabel">Message:</label>
                    <label className="rightLabel">{selectedLog?.Message}</label>
                  </div>
                )}
                {selectedLog && selectedLog?.CreateDate && (
                  <div>
                    <label className="leftLabel">Start Date:</label>
                    <label className="rightLabel">
                      {moment(selectedLog?.CreateDate).format(
                        "DD-MM-YYYY hh:mm"
                      )}
                    </label>
                  </div>
                )}
                {selectedLog && selectedLog?.Exception && (
                  <div>
                    <label className="leftLabel">Exception:</label>
                    <label className="rightLabel">
                      {selectedLog?.Exception}
                    </label>
                  </div>
                )}
                {selectedLog && selectedLog?.StackTrace && (
                  <div>
                    <label className="leftLabel">Trace:</label>
                    <label className="rightLabel">
                      {selectedLog?.StackTrace}
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    labels: state.labels,
  };
}
export default connect(mapStateToProps)(SelectedLog);
