import React, { Component } from "react";
import { List } from "../../../components";
import "./MemberDetail.scss";

class MembersList extends Component {
    render() {
        const {
            membersList,
            handleMemberClick,
            selectedMember,
        } = this.props;
        return (
            <div>
                {membersList !== null ?
                    <List className="company-list" >
                        {membersList.map((item, index) => (
                            <List.ListItem
                                onClick={() => handleMemberClick(item)}
                                key={item.UserId}
                                isSelected={selectedMember ? selectedMember.UserId === item.UserId : null}
                            >
                                <div className={`dashItemImg ${"no-dp"}`}>
                                    <img
                                        src={item.ProfilePicture}
                                        alt={item.ProfilePicture}
                                    />
                                </div>
                                <div className="CompNameCnt">
                                    <div
                                        className={"defaultCompanyName"
                                        }
                                    >
                                        {item.UserFirstname + " " + (item.UserLastname ? item.UserLastname : "")}
                                    </div>
                                    <div className="CompAddress">{item.UserEmail}</div>
                                </div>
                            </List.ListItem>
                        ))}
                    </List> : null
                }
            </div>
        );
    }
}
export default MembersList;
