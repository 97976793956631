import { ApiUrls } from "../../Services/apiUrls";
import axios from "axios";

export const GetAllEmailsApi = () => {
  return axios
    .get(ApiUrls.emailTemplates.getAll)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const SendEmailApi = (email) => {
  return axios
    .post(ApiUrls.emailTemplates.SendEmail, email)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const UpdateEmailApi = (email) => {
  return axios
    .put(ApiUrls.emailTemplates.UpdateEmail, email)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const DeleteEmail = (payload) => {
  return axios
    .delete(ApiUrls.emailTemplates.Delete, { data: payload })
    .then(({ data }) => data)
    .catch((response) => response);
};
