import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import DatePicker from "../../components/DatePicker/DatePicker";
import { withRouter } from "react-router-dom";
import Chart from "react-apexcharts";
import "./Statistics.scss";

import {
  getTotalIpro,
  getTotalSearcher,
  getExpenseLogs,
} from "../../Services/apiCalls";
import UserDetails from "./components/UserDetails";
import UsersList from "./components/UsersList";
import { Button, Column, Select } from "../../components";
import react from "react";
import { CSVLink, CSVDownload } from "react-csv";

class Statistics extends Component {
  state = {
    StartDate: moment()
      .subtract(30, "days")
      .format("YYYY-MM-DDTHH:mm:ss"),
    EndDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
    totalIpros: 0,
    totalSearcher: 0,
    isCollapsed1: false,
    isCollapsed2: false,
    UserList: null,
    iProList: null,
    usersWithRolesAndSkills: null,
    usersWithRoles: null,
    usersWithSkills: null,
    usersWithSkillsButNoRoles: null,
    usersWithRolesButNoSkills: null,
    searcherList: null,
    selectedUser: null,
    totalCost: 0,
    TypeList: [
      { value: 1, label: "iPro" },
      { value: 2, label: "Recruiter" },
    ],
    type2List: [
      { value: 1, label: "All iPro " },
      { value: 2, label: "iPro with Roles & Skills " },
      { value: 3, label: "iPro with Roles " },
      { value: 4, label: "iPro with Skills " },
      { value: 5, label: "iPro with Roles but no Skills " },
      { value: 6, label: "iPro with Skills but no Roles " },
    ],
    UserType: null,
    user2Type: null,
    options: {
      chart: {
        type: "donut",
      },
      labels: ["iPros", "Recruiter"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  componentDidMount = () => {
    this.onSubmit();
    this.handleFormSelectChange({ value: 1, label: "iPro" });
    this.onUserType2Change({ value: 1 });
  };

  handleUserClick = (user) => {
    this.setState({
      selectedUser: user,
    });
  };

  handleFormSelectChange = (selectedOption) => {
    const { iProList, searcherList, user2Type } = this.state;
    if (!selectedOption) return;

    if (selectedOption.value === 1) {
      this.setState({
        UserList: iProList,
        UserType: { value: 1, label: "iPro" },
      });
      if (user2Type) {
        this.onUserType2Change(user2Type);
      }
    }
    if (selectedOption.value === 2) {
      this.setState({
        UserList: searcherList,
        UserType: { value: 2, label: "Recruiter" },
      });
    }
  };

  handleStartDateChange = (date) => {
    this.setState(
      {
        invalidStartDate: false,
        StartDate: moment(date).format("YYYY-MM-DDTHH:mm:ss"),
      },
      () => {
        this.onSubmit();
      }
    );
  };

  handleEndDateChange = (date) => {
    this.setState(
      {
        invalidEndDate: false,
        EndDate: moment(date).format("YYYY-MM-DDTHH:mm:ss"),
      },
      () => {
        this.onSubmit();
      }
    );
  };
  onUserType2Change = (selectedOption) => {
    const {
      iProList,
      type2List,
      usersWithRoles,
      usersWithSkills,
      usersWithSkillsButNoRoles,
      usersWithRolesButNoSkills,
      usersWithRolesAndSkills,
    } = this.state;
    if (!selectedOption) return;
    let filteredResult = [];
    if (selectedOption.value === 1) {
      this.setState({
        UserList: iProList,
        user2Type: type2List[0],
      });
      return;
    }

    if (selectedOption.value == 2 && usersWithRolesAndSkills.length > 0) {
      iProList.map((usr) => {
        usersWithRolesAndSkills.map((item) => {
          if (usr.UserId == item) {
            filteredResult.push(usr);
          }
        });
      });
    }
    if (selectedOption.value == 3 && usersWithRoles.length > 0) {
      iProList.map((usr) => {
        usersWithRoles.map((item) => {
          if (usr.UserId == item) {
            filteredResult.push(usr);
          }
        });
      });
      this.setState({
        user2Type: type2List[2],
      });
    }

    if (selectedOption.value == 4 && usersWithSkills.length > 0) {
      iProList.map((usr) => {
        usersWithSkills.map((item) => {
          if (usr.UserId == item) {
            filteredResult.push(usr);
          }
        });
      });
    }
    if (selectedOption.value == 5 && usersWithRolesButNoSkills.length > 0) {
      iProList.map((usr) => {
        usersWithSkills.map((item) => {
          if (usr.UserId == item) {
            filteredResult.push(usr);
          }
        });
      });
    }

    if (selectedOption.value == 6 && usersWithSkillsButNoRoles.length > 0) {
      iProList.map((usr) => {
        usersWithSkills.map((item) => {
          if (usr.UserId == item) {
            filteredResult.push(usr);
          }
        });
      });
    }
    this.setState({
      UserList: filteredResult,
      user2Type: selectedOption,
    });
  };

  onSubmit = () => {
    const {
      StartDate,
      EndDate,
      UserType,
      type2NewList,
      type2List,
    } = this.state;
    var newUserTypeList = [
      { value: 1, label: "All iPro " },
      { value: 2, label: "iPro with Roles & Skills " },
      { value: 3, label: "iPro with Roles " },
      { value: 4, label: "iPro with Skills " },
      { value: 5, label: "iPro with Roles but no Skills " },
      { value: 6, label: "iPro with Skills but no Roles " },
    ];
    getTotalIpro(StartDate, EndDate)
      .then((data) => {
        let type2ListRes = [];
        newUserTypeList.map((item) => {
          switch (item.value) {
            case 1:
              item.label = item.label + data.total;
              type2ListRes.push(item);
              break;
            case 2:
              item.label = item.label + data.items.WithRolesAndSkills.length;
              type2ListRes.push(item);
              break;
            case 3:
              item.label = item.label + data.items.WithRoles.length;
              type2ListRes.push(item);
              break;
            case 4:
              item.label = item.label + data.items.WithSkills.length;
              type2ListRes.push(item);
              break;
            case 5:
              item.label = item.label + data.items.WithRolesButNoSkills.length;
              type2ListRes.push(item);
              break;
            default:
              item.label = item.label + data.items.WithSkillsButNoRoles.length;
              type2ListRes.push(item);
          }
        });
        this.setState(
          {
            totalIpros: data.total,
            iProList: data.items.Users,
            usersWithRoles: data.items.WithRoles,
            usersWithRolesAndSkills: data.items.WithRolesAndSkills,
            UserList: data.items.Users,
            usersWithSkills: data.items.WithSkills,
            usersWithRolesButNoSkills: data.items.WithRolesButNoSkills,
            usersWithSkillsButNoRoles: data.items.WithSkillsButNoRoles,
            type2List: type2ListRes,
          },
          () => {
            this.handleFormSelectChange({ value: 1, label: "iPro" });
            this.onUserType2Change(type2List[0]);
          }
        );
      })
      .catch((err) => {});

    getTotalSearcher(StartDate, EndDate)
      .then((data) => {
        this.setState(
          {
            totalSearcher: data.total,
            searcherList: data.items,
          },
          () => {
            if (UserType && UserType.value == 2)
              this.setState({
                UserList: data.items,
              });
          }
        );
      })
      .catch((err) => {});

    getExpenseLogs(StartDate, EndDate).then((data) => {
      this.setState({
        totalCost: data.items,
      });
    });
  };

  render() {
    const { labels } = this.props;
    const {
      StartDate,
      EndDate,
      totalIpros,
      totalSearcher,
      options,
      UserList,
      selectedUser,
      isCollapsed1,
      isCollapsed2,
      totalCost,
    } = this.state;
    let series = [totalIpros, totalSearcher];

    return (
      <div className="main-div columns-wrapper">
        <Column collapse={isCollapsed1}>
          <Column.Collapsed
            text={"Statistics"}
            onClick={() => this.setState({ isCollapsed1: false })}
            tooltipButton={labels.ADMIN_EXPAND}
            // isHelpActive={isHelpActive}
            tooltipHelp={labels.ADMIN_EXPAND_BTN}
            tooltipPlace="left"
            testId={"column-1-expand"}
          />
          <Column.Head>
            <div className="heading">
              <p>{"Statistics"}</p>
            </div>
            <Button
              onClick={() => this.setState({ isCollapsed1: true })}
              className="collapseBtn"
              tooltipButton={labels.ADMIN_COLLAPSE}
              tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
              tooltipPlace="left"
              // isHelpActive={isHelpActive}
              testId={"column-1-collapse"}
            />
          </Column.Head>

          <Column.Body>
            <div className="fields-row">
              <div className="labelCalender">
                <label className="form-label">{"Start Date"}:</label>
                <label className="datepicker-input datepicker-wrapper">
                  <DatePicker
                    name="StartDate"
                    dateFormat="DD-MM-YYYY"
                    selected={StartDate && moment(StartDate)}
                    onChange={this.handleStartDateChange}
                    todayButton="Today"
                    placeholderText={"DD-MM-YYYY"}
                    maxDate={EndDate && moment(EndDate)}
                    autoComplete="off"
                  />
                </label>
              </div>
              <div className="labelCalender">
                <label className="form-label">{"End Date"}:</label>
                <label className="datepicker-input datepicker-wrapper">
                  <DatePicker
                    name="EndDate"
                    dateFormat="DD-MM-YYYY"
                    selected={EndDate && moment(EndDate)}
                    onChange={this.handleEndDateChange}
                    todayButton="Today"
                    minDate={StartDate && moment(StartDate)}
                    maxDate={moment()}
                    placeholderText={"DD-MM-YYYY"}
                    autoComplete="off"
                  />
                </label>
              </div>
            </div>
            {totalIpros == 0 && totalSearcher == 0 ? null : (
              <React.Fragment>
                <div className="chart">
                  <Chart options={options} series={series} type="donut" />
                </div>
              </React.Fragment>
            )}
            <React.Fragment>
              {totalCost != 0 ? (
                totalIpros + totalSearcher != 0 ? (
                  <p className="statisticsText">
                    {" "}
                    {totalIpros + totalSearcher} signups at {totalCost}$ with{" "}
                    {(totalCost / (totalIpros + totalSearcher)).toFixed(4)}$
                    each.
                  </p>
                ) : (
                  <p className="statisticsText">
                    {" "}
                    {totalIpros + totalSearcher} signups at {totalCost}$ with 0$
                    each.
                  </p>
                )
              ) : (
                <p className="statisticsText">
                  {" "}
                  {totalIpros + totalSearcher} signups at 0$ with 0$ each.
                </p>
              )}
            </React.Fragment>
            <div style={{ textAlign: "end", margin: "10px 0px" }}>
              <CSVLink
                data={UserList || []}
                filename={`statistic-${moment(new Date()).format(
                  "DD-MM-YYYY hh:mm"
                )}`}
              >
                Download CSV
              </CSVLink>
            </div>
            {totalIpros == 0 && totalSearcher == 0 ? null : (
              <React.Fragment>
                <div className="input-select-field setHeight50p">
                  <h6>{"User Types"}</h6>
                  <Select
                    name="Type"
                    className={`select-input`}
                    placeholder={"Select Type"}
                    value={this.state.UserType}
                    onChange={(selectedOption) =>
                      this.handleFormSelectChange(selectedOption)
                    }
                    options={this.state.TypeList}
                    clearable={false}
                    searchable={false}
                  />
                </div>
              </React.Fragment>
            )}
            {this.state.UserType && this.state.UserType.value == 1 && (
              <React.Fragment>
                <div className="input-select-field setHeight50p">
                  <h6>{"iPro"}</h6>
                  <Select
                    name="Type"
                    className={`select-input`}
                    placeholder={"Select Type"}
                    value={this.state.user2Type}
                    onChange={(selectedOption) =>
                      this.onUserType2Change(selectedOption)
                    }
                    options={this.state.type2List}
                    clearable={false}
                    searchable={false}
                  />
                </div>
              </React.Fragment>
            )}
            <React.Fragment>
              {UserList && UserList.length > 0 ? (
                <UsersList
                  UserList={UserList}
                  handleUserClick={this.handleUserClick}
                  selectedUser={selectedUser}
                />
              ) : (
                <div className="no-item-label">
                  <label>{labels.ADMIN_NO_ITEM_TO_DISPLAY}</label>
                </div>
              )}
            </React.Fragment>
          </Column.Body>
        </Column>
        <Column collapse={isCollapsed2}>
          <Column.Collapsed
            text={"User Details"}
            onClick={() => this.setState({ isCollapsed2: false })}
            tooltipButton={labels.ADMIN_EXPAND}
            // isHelpActive={isHelpActive}
            tooltipHelp={labels.ADMIN_EXPAND_BTN}
            tooltipPlace="left"
            testId={"column-1-expand"}
          />
          <Column.Head>
            <div className="heading">
              <p>{"User Details"}</p>
            </div>
            <Button
              onClick={() => this.setState({ isCollapsed2: true })}
              className="collapseBtn"
              tooltipButton={labels.ADMIN_COLLAPSE}
              tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
              tooltipPlace="left"
              // isHelpActive={isHelpActive}
              testId={"column-1-collapse"}
            />
          </Column.Head>
          <Column.Body>
            <React.Fragment>
              {selectedUser !== null &&
              totalIpros != 0 &&
              totalSearcher != 0 ? (
                <UserDetails selectedUser={selectedUser} />
              ) : null}
            </React.Fragment>
          </Column.Body>
        </Column>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    labels: state.labels,
  };
}

const actions = {};

export default connect(mapStateToProps, actions)(withRouter(Statistics));
