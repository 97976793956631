import React from "react";
import { getAllCountriesApi } from "../../../Services/apiCalls";
import loadingIcon from "../../../Assests/Images/loading.gif";
import { CustomInput, Select } from "../../../components";

class SaveAdminForm extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      addSystemlabelFieldChange,
      addSystemLabelData,
      languages,
      addSystemLabelSelectChange,
      handleSystemlabelSave,
      labels,
    } = this.props;
    const {
      selectedLanguage,
      systemLabelIdentifier,
      systemLabelmsg,
      Categories,
      UserTypes,
      Modules,
      SubModules,
      SelectedModule,
      SelectedCategory,
      SelectedUserType,
      SelectedSubModule,
    } = addSystemLabelData;
    return (
      <div className="system_label_filter">
        <div className="input-field">
          <h6>{labels.ADMIN_LANGUAGES}</h6>
          <select
            name="selectedLanguage"
            test-data-id={"LabelsFilterSelectLanguage"}
            value={selectedLanguage}
            onChange={(e) => addSystemLabelSelectChange(e)}
          >
            {languages && languages.length > 0
              ? languages.map((item) => (
                  <option value={item.value} key={item.value}>
                    {item.label}
                  </option>
                ))
              : ""}
          </select>
        </div>
        <div className="input-field">
          <h6>{labels.ADMIN_MODULES_LABEL}</h6>
          <select
            name="SelectedModule"
            test-data-id={"LabelsFilterSelectLanguage"}
            value={SelectedModule}
            onChange={(e) => addSystemLabelSelectChange(e)}
          >
            {Modules && Modules.length > 0
              ? Modules.map((item) => (
                  <option value={item.value} key={item.value}>
                    {item.label}
                  </option>
                ))
              : ""}
          </select>
        </div>

        <div className="input-field">
          <h6>{labels.ADMIN_USER_TYPES_LABEL}</h6>
          <select
            name="SelectedUserType"
            test-data-id={"LabelsFilterSelectLanguage"}
            value={SelectedUserType}
            onChange={(e) => addSystemLabelSelectChange(e)}
          >
            {UserTypes && UserTypes.length > 0
              ? UserTypes.map((item) => (
                  <option value={item.value} key={item.value}>
                    {item.label}
                  </option>
                ))
              : ""}
          </select>
        </div>

        <div className="input-field">
          <h6>{labels.ADMIN_SUBMODULES_LABEL}</h6>
          <select
            name="SelectedSubModule"
            test-data-id={"LabelsFilterSelectLanguage"}
            value={SelectedSubModule}
            onChange={(e) => addSystemLabelSelectChange(e)}
          >
            {SubModules && SubModules.length > 0
              ? SubModules.map((item) => (
                  <option value={item.value} key={item.value}>
                    {item.label}
                  </option>
                ))
              : ""}
          </select>
        </div>
        <div className="input-field">
          <h6>{labels.ADMIN_CATEGORIES_LABEL}</h6>
          <select
            name="SelectedCategory"
            test-data-id={"LabelsFilterSelectLanguage"}
            value={SelectedCategory}
            onChange={(e) => addSystemLabelSelectChange(e)}
          >
            {Categories && Categories.length > 0
              ? Categories.map((item) => (
                  <option value={item.value} key={item.value}>
                    {item.label}
                  </option>
                ))
              : ""}
          </select>
        </div>

        <div
          className={`${
            !systemLabelIdentifier ? "inValid " : " "
          } input-field-custom `}
        >
          <h6>{labels.ADMIN_SYSTEM_LABELS_IDENTIFIER}</h6>
          <div>
            <input
              name={"systemLabelIdentifier"}
              type={"text"}
              value={systemLabelIdentifier}
              onChange={(e) => addSystemlabelFieldChange(e)}
              test-data-id={"input-label-identifier"}
            />
          </div>
        </div>

        <div
          className={`${
            !systemLabelmsg ? "inValid " : " "
          } input-field-custom `}
        >
          <h6>{labels.ADMIN_LABEL_TEXT}</h6>
          <div>
            <input
              name={"systemLabelmsg"}
              type={"text"}
              value={systemLabelmsg}
              onChange={(e) => addSystemlabelFieldChange(e)}
              test-data-id={"input-label-text"}
              onBlur={handleSystemlabelSave}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SaveAdminForm;
