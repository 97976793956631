import React, { Component } from "react";
import { connect } from "react-redux";
import "./selectedSearches.scss";
import moment from "moment";

class SelectedSearch extends Component {
  render() {
    const { labels, selectedUser } = this.props;
    return (
      <div className="selected-searches">
        {!selectedUser.UserId ? (
          <React.Fragment>{labels.NO_ITEM_LABEL}</React.Fragment>
        ) : (
          <div className="MsgWrapper">
            <div className="feedbackWrap">
              <div className="msgItemDiv">
                <div>
                  <label className="leftLabel">Search Name:</label>
                  <label className="rightLabel">
                    {selectedUser?.SearchName || ""}
                  </label>
                </div>
                {selectedUser && selectedUser.SavedSearchId && (
                  <div>
                    <label className="leftLabel">Search ID:</label>
                    <label className="rightLabel">
                      {selectedUser.SavedSearchId}
                    </label>
                  </div>
                )}
                {selectedUser && selectedUser.UserId && (
                  <div>
                    <label className="leftLabel">User ID:</label>
                    <label className="rightLabel">{selectedUser.UserId}</label>
                  </div>
                )}
                {selectedUser && selectedUser.UpdatedOn && (
                  <div>
                    <label className="leftLabel">Date:</label>
                    <label className="rightLabel">
                      {moment(selectedUser?.UpdatedOn).format(
                        "DD-MM-YYYY hh:mm"
                      )}
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    labels: state.labels,
  };
}
export default connect(mapStateToProps)(SelectedSearch);
