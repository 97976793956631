import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import DatePicker from "../../components/DatePicker/DatePicker";
import { withRouter } from "react-router-dom";
import "../AdminEmail/email.scss";
import { getAllErrorLogs } from "../../Services/apiCalls";
import { Button, Column, Select } from "../../components";
import { toggleLoadingAction } from "../../Redux/actions";
import SearchesList from "./components/searchesList";
import SelectedLogs from "./components/selectedLogs";

class ErrorLogs extends Component {
  state = {
    StartDate: moment()
      .subtract(30, "days")
      .format("YYYY-MM-DDTHH:mm:ss"),
    EndDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
    type: { value: "Contains", label: "Contains" },
    filterText: "",
    isCollapsed2: false,
    selectedUser: null,
    selectedLog: "",
  };

  GetAllLookups = () => {
    this.onSubmit();
  };

  componentDidMount = () => {
    this.GetAllLookups();
  };

  handleStartDateChange = (date) => {
    this.setState(
      {
        invalidStartDate: false,
        StartDate: moment(date).format("YYYY-MM-DDTHH:mm:ss"),
      },
      () => {
        this.onSubmit();
      }
    );
  };

  handleEndDateChange = (date) => {
    this.setState(
      {
        invalidEndDate: false,
        EndDate: moment(date).format("YYYY-MM-DDTHH:mm:ss"),
      },
      () => {
        this.onSubmit();
      }
    );
  };

  handleFormSelectChange = (selectedOption) => {
    this.setState(
      {
        type: selectedOption,
      },
      () => {
        this.onSubmit();
      }
    );
  };

  handleSearchChange = (e) => {
    const { value } = e.target;
    this.setState({ filterText: value });
  };

  handleClick = (value) => {
    this.setState((st) => ({ ...st, selectedLog: value }));
  };

  onSubmit = () => {
    this.props.toggleLoadingAction(true);
    const { StartDate, EndDate, type, filterText } = this.state;

    getAllErrorLogs(
      type?.value,
      moment(StartDate).format("MM/DD/YYYY"),
      moment(EndDate).format("MM/DD/YYYY"),
      filterText
    )
      .then((data) => {
        this.setState((st) => ({
          ...st,
          data: data?.items,
          selectedLog: data?.items?.[0],
        }));

        this.props.toggleLoadingAction(false);
      })
      .catch((err) => {
        this.setState((st) => ({ ...st, data: [] }));
        this.props.toggleLoadingAction(false);
      });
  };

  render() {
    const { labels } = this.props;
    const {
      StartDate,
      EndDate,
      isCollapsed1,
      isCollapsed2,
      filterText,
      data,
      selectedLog,
    } = this.state;

    return (
      <div className="main-div-reports columns-wrapper">
        <Column collapse={isCollapsed1}>
          <Column.Collapsed
            text={"Error Logs"}
            onClick={() => this.setState({ isCollapsed1: false })}
            tooltipButton={labels.ADMIN_EXPAND}
            tooltipHelp={labels.ADMIN_EXPAND_BTN}
            tooltipPlace="left"
            testId={"column-1-expand"}
          />
          <Column.Head>
            <div className="heading">
              <p>{"Error Logs"}</p>
            </div>
            <Button
              onClick={() => this.setState({ isCollapsed1: true })}
              className="collapseBtn"
              tooltipButton={labels.ADMIN_COLLAPSE}
              tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
              tooltipPlace="left"
              // isHelpActive={isHelpActive}
              testId={"column-1-collapse"}
            />
          </Column.Head>

          <Column.Body>
            <div className="fields-row">
              <div className="labelCalender">
                <label className="form-label">{"Start Date"}:</label>
                <label className="datepicker-input datepicker-wrapper">
                  <DatePicker
                    name="StartDate"
                    dateFormat="DD-MM-YYYY"
                    selected={StartDate && moment(StartDate)}
                    onChange={this.handleStartDateChange}
                    todayButton="Today"
                    placeholderText={"DD-MM-YYYY"}
                    maxDate={EndDate && moment(EndDate)}
                    autoComplete="off"
                  />
                </label>
              </div>
              <div className="labelCalender">
                <label className="form-label">{"End Date"}:</label>
                <label className="datepicker-input datepicker-wrapper">
                  <DatePicker
                    name="EndDate"
                    dateFormat="DD-MM-YYYY"
                    selected={EndDate && moment(EndDate)}
                    onChange={this.handleEndDateChange}
                    todayButton="Today"
                    minDate={StartDate && moment(StartDate)}
                    maxDate={moment()}
                    placeholderText={"DD-MM-YYYY"}
                    autoComplete="off"
                  />
                </label>
              </div>
            </div>

            <React.Fragment>
              <div className="input-select-field setHeight50p">
                <h6>{"Type"}</h6>
                <Select
                  name="Type"
                  className={`select-input`}
                  placeholder={"Select Type"}
                  value={this?.state?.type}
                  onChange={(selectedOption) =>
                    this.handleFormSelectChange(selectedOption)
                  }
                  options={[
                    { label: "Contains", value: "Contains" },
                    { label: "Not Contains", value: "Not Contains" },
                    { label: "Equal", value: "Equal" },
                    { label: "All", value: "All" },
                  ]}
                  clearable={false}
                  searchable={false}
                />
              </div>
            </React.Fragment>
            <React.Fragment>
              <div
                className={"input-field-custom search-input"}
                style={{ minHeight: "40px" }}
              >
                <h6>{labels.ADMIN_SEACRH_BY_TEXT_LABEL}</h6>
                <div>
                  <input
                    test-data-id={"input-search-by-text"}
                    name={"SearchByText"}
                    type={"text"}
                    value={filterText || ""}
                    onChange={this.handleSearchChange}
                    onBlur={this.onSubmit}
                  />
                </div>
              </div>
            </React.Fragment>
            <React.Fragment>
              {data?.length > 0 ? (
                <SearchesList
                  data={data}
                  handleClick={this.handleClick}
                  selectedLog={selectedLog}
                />
              ) : (
                <div className="no-item-label">
                  <label>{labels.ADMIN_NO_ITEM_TO_DISPLAY}</label>
                </div>
              )}
            </React.Fragment>
          </Column.Body>
        </Column>
        <Column collapse={isCollapsed2}>
          <Column.Collapsed
            text={"Log Details"}
            onClick={() => this.setState({ isCollapsed2: false })}
            tooltipButton={labels.ADMIN_EXPAND}
            tooltipHelp={labels.ADMIN_EXPAND_BTN}
            tooltipPlace="left"
            testId={"column-1-expand"}
          />
          <Column.Head>
            <div className="heading">
              <p>{"Log Details"}</p>
            </div>
            <Button
              onClick={() => this.setState({ isCollapsed2: true })}
              className="collapseBtn"
              tooltipButton={labels.ADMIN_COLLAPSE}
              tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
              tooltipPlace="left"
              // isHelpActive={isHelpActive}
              testId={"column-1-collapse"}
            />
          </Column.Head>
          <Column.Body>
            <React.Fragment>
              {selectedLog !== null && (
                <SelectedLogs selectedLog={selectedLog} />
              )}
            </React.Fragment>
          </Column.Body>
        </Column>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    labels: state.labels,
  };
}

const actions = {
  toggleLoadingAction,
};

export default connect(mapStateToProps, actions)(withRouter(ErrorLogs));
