import React, { Component } from "react";
import { Column, Button, List, CustomInput } from "../../components";
import { getMembers, getResumesApi } from "../../Services/apiCalls";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MembersList from "./components/MembersList";
import MemberDetail from "./components/MemberDetail";
import ResumeDetail from "./components/ResumeDetail";
import {
    setNotificationAction,
    toggleLoadingAction,
} from "../../Redux/actions";

class Members extends Component {
    state = {
        membersList: null,
        selectedMember: null,
        filteredMembers: [],
        selectedResume: null,
        isCollapsed1: false,
        isCollapsed2: true,
    };
    componentDidMount = () => {
        this.props.toggleLoadingAction(true);
        getMembers().then(data => {
            const members = data.items.filter(item => item.ResumeId != 0);
            this.setState({
                membersList: members,
                filteredMembers: members
            });
            this.props.toggleLoadingAction(false);
        }).catch(err => { });
    };
    handlegetResumeDetail = (id) => {
        getResumesApi(id)
            .then((data) => {
                if (data.success) {
                    this.setState({
                        selectedResume: data.items,
                        isCollapsed2: false,
                        isCollapsed1: true
                    });
                }
            })
            .catch((response) => { });
    }
    onFormFieldChange = (e) => {
        const { name, value } = e.target;
        var data = this.state.membersList;
        const membersFilter = data.filter((item) =>
            item.UserFirstname.toLowerCase().includes(value.toLowerCase())
        );
        this.setState({
            [name]: value,
            filteredMembers: membersFilter,
        });
    };

    handleMemberClick = (member) => {
        this.setState({
            selectedMember: member
        });
        if (member.IsFreelancer)
            this.handlegetResumeDetail(member.ResumeId);
    };

    render() {
        const { labels } = this.props;
        const {
            filteredMembers,
            adminRoleInput,
            selectedMember,
            selectedResume,
            isCollapsed1,
            isCollapsed2
        } = this.state;
        return (
            <div className="admin-management-role-wraper columns-wrapper">
                <Column collapse={isCollapsed1} className="col-1">
                    <Column.Collapsed
                        text={"Members List"}
                        onClick={() => this.setState({ isCollapsed1: false, isCollapsed2: true })}
                        tooltipButton={"Members List"}
                        tooltipHelp={"Members List Expand"}
                        tooltipPlace="left"
                        testId={"column-1-expand"}
                    />
                    <Column.Head>
                        <div className="heading">
                            <p>{labels.ADMIN_MEMBERS_TITLE}</p>
                        </div>
                        <Button
                            onClick={() => this.setState({ isCollapsed1: true, isCollapsed2: false })}
                            className="collapseBtn"
                            tooltipButton={labels.ADMIN_COLLAPSE}
                            tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
                            tooltipPlace="left"
                            testId={"column-1-collapse"}
                        />
                    </Column.Head>
                    <Column.Body>
                        <div className="admin-management-role-col1">
                            <div className="adminRoleInput-field ">
                                <div
                                    className={`${!adminRoleInput ? "inValid " : " "
                                        } input-field `}
                                >
                                    <h6>{labels.ADMIN_MEMBERS_PLACEHOLDER}</h6>
                                    <div>
                                        <input
                                            name={"adminRoleInput"}
                                            type={"text"}
                                            value={adminRoleInput || ""}
                                            onChange={(e) => this.onFormFieldChange(e)}
                                            test-data-id={"admin-management-role-input"}
                                            placeholder={labels.ADMIN_MEMBERS_PLACEHOLDER}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="list-wrapper">
                            </div>
                        </div>
                        {filteredMembers != null && filteredMembers.length > 0 ?
                            <MembersList
                                membersList={filteredMembers}
                                handleMemberClick={this.handleMemberClick}
                                selectedMember={selectedMember}
                            /> : <div className="no-item-label">
                                <label>{labels.ADMIN_NO_ITEM_TO_DISPLAY}</label>
                            </div>
                        }
                    </Column.Body>
                </Column>
                <Column>
                    <Column.Head>
                        <div className="heading">
                            <p>{"Member Detail"}</p>
                        </div>
                    </Column.Head>
                    <Column.Body>
                        <React.Fragment>
                            {
                                selectedMember !== null ?
                                    <MemberDetail
                                        getResumeDetail={this.handlegetResumeDetail}
                                        selectedMember={selectedMember}
                                    /> :
                                    null
                            }
                        </React.Fragment>
                    </Column.Body>
                </Column>
                {
                    selectedMember !== null && selectedResume && selectedMember.IsFreelancer ?
                        <Column collapse={isCollapsed2} className="col-1">
                            <Column.Collapsed
                                text={"Member Resume"}
                                onClick={() => this.setState({ isCollapsed2: false, isCollapsed1: true })}
                                tooltipButton={"Member Resume"}
                                tooltipHelp={"Member Resume"}
                                tooltipPlace="left"
                                testId={"column-2-expand"}
                            />
                            <Column.Head>
                                <div className="heading">
                                    <p>{"Member Resume"}</p>
                                </div>
                                <Button
                                    onClick={() => this.setState({ isCollapsed2: true, isCollapsed1: false, selectedResume: null, })}
                                    className="collapseBtn"
                                    tooltipButton={"COLLAPSE"}
                                    tooltipPlace="left"
                                    testId={"column-2-collapse"}
                                />
                            </Column.Head>
                            <Column.Body>
                                <ResumeDetail resume={selectedResume} />
                            </Column.Body>
                        </Column > : null}
            </div >
        );
    }
}
function mapStateToProps(state) {
    return {
        isLoading: state.isLoading,
        labels: state.labels,
    };
}

const actions = {
    setNotificationAction,
    toggleLoadingAction,
};

export default connect(
    mapStateToProps,
    actions
)(withRouter(Members));