import React, { Component } from "react";
import { List } from "../../../components";
import "./UsersList.scss";
class UsersList extends Component {
    render() {
        const {
            UserList,
            handleUserClick,
            selectedUser,
        } = this.props;
        return (
            <div>
                {UserList !== null ?
                    <List className="company-list" >
                        {UserList.map((item, index) => (
                            <List.ListItem
                                onClick={() => handleUserClick(item)}
                                key={item.UserId}
                                isSelected={selectedUser ? selectedUser.UserId === item.UserId : null}
                            >
                                <div className={`dashItemImg ${"no-dp"}`}>
                                    <img
                                        className="roundImg"
                                        src={item.UserName}
                                        alt={item.UserName}
                                    />
                                </div>
                                <div className="CompNameCnt">
                                    <div
                                        className={"defaultCompanyName"
                                        }
                                    >
                                        {item.UserFirstName}
                                    </div>
                                    <div className="CompAddress">{item.UserEmail}</div>
                                </div>
                            </List.ListItem>
                        ))}
                    </List> : null
                }
            </div>
        );
    }
}
export default UsersList;
