import React, { Component } from "react";
import { Column, Button, CustomInput } from "../../components";
import "./LookupCorrelation.scss";
import {
  AddCorrelationData,
  DeleteLookupCorrelatedDataItem,
  GetLookupDataByType,
  GetCorrelationData,
  UpdateLookupCorrelatedDataItem,
} from "./apiCalls";
import { getModules } from "../../Services/apiCalls";
import LookupCorrelationsList from "./components/LookupCorrelationsList.jsx";
import LookupCorrelationDetails from "./components/LookupCorrelationDetails.jsx";
import LookupCorrelationDataList from "./components/LookupCorrelationDataList.jsx";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { SALES, COMMON } from "../../Services/labels";
import loadingIcon from "../../Assests/Images/loading.gif";
import doneIcon from "../../Assests/Images/done.png";
import failedIcon from "../../Assests/Images/failed.png";

import {
  setNotificationAction,
  toggleLoadingAction,
} from "../../Redux/actions";
import { validateInputField } from "../../utilities/validator";
import { isValidEmail } from "../../utilities/validations";
import { updateSystemLabel } from "../SystemLabels/components/apiCalls";
class LookupCorrelation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed1: false,
      isCollapsed2: false,
      isCollapsed3: true,
      CorrelationUpdatedValue: "",
      lookupTypeValueForCorrelationData: "",
      searchKey: "",
      selectedUserTypes: 0,
      selectedCategories: 0,
      selectedModules: 0,
      selectedSubModules: 0,
      selectedLanguage: -1,
      searchModeValue: 1,
      systemLabelItem: null,
      lookupTypeForCorrelationData: [],
      labelsFilterData: {
        SearchByText: "",
        SearchByIdentifier: "",
        Name: "",
        UserId: -1,
        WorkPlaceId: 0,
        loadingValue: 0,
        languagesList: [],
        selectedLanguage: 1,
      },
      LookupData: [],
      CorrelationTableData: [],
      selectedItem: {},

      isHelpActive: false,
      isQuick: true,
      lastFocusField: "",
      SubModules: [],
      SystemLabelsList: [],
      Categories: [],
      Categories: [],
      lookuptypesdataFilter: [],
      UserTypes: [],
      LookupTypesData: [],
      LookupType: [
        { value: 1, label: "Role" },
        { value: 2, label: "Skill" },
        { value: 3, label: "Keyword" },
        { value: 4, label: "Certification" },
        { value: 5, label: "Education" },
        { value: 6, label: "Industry" },
      ],
      LookUpSearchMode: "Profile",
      languages: [],
      LookupTypeCorrelationDropDownData: {
        Profile: [
          { value: "Profile", label: "Roles for Role" },
          { value: "ProfileSkills", label: "Skills for Role" },
          { value: "ProfileCertifications", label: "Certifications for Role" },
        ],
        Skill: [
          { value: "Skill", label: "Skills for Skill" },
          { value: "SkillProfiles", label: "Roles for Skill" },
        ],
        Keyword: [{ value: "Keyword", label: "Keyword for Keyword" }],
        Certification: [
          { value: "Certification", label: "Certification for Certification" },
          { value: "CertificationProfiles", label: "Roles for Certification" },
        ],
        Education: [{ value: "Education", label: "Education for Education" }],
        Industry: [{ value: "Industry", label: "Industry for Industry" }],
      },
      lookupCorrelationDropDownSelected: [],
      DataForCorrelationDataList: [],
      DataForCorrelationDataListFilter: [],
      CorrelationValuePk: 0,
    };
  }
  componentDidMount = () => {
    this.setState({ SearchByText: "Search " + this.state.LookUpSearchMode });
    this.GetlookUpTypes(this.state.LookUpSearchMode);
  };

  GetlookUpTypes = (lookupType) => {
    GetLookupDataByType(lookupType).then((response) => {
      if (response.success) {
        var dynamicId = lookupType + "Id";
        var dynamicValue = lookupType + "Value";
        const newList = response.items.map((item) => {
          const newItem = {
            Id: item[dynamicId],
            Title: item[dynamicValue],
          };
          return newItem;
        });
        this.setState({
          LookupTypesData: newList,
          LookUpSearchMode: lookupType,
          lookuptypesdataFilter: newList,
          CorrelationTableData: []
        });
      }
    });
    this.setLookupToLookupCoorelation(lookupType);
  };

  getCorrelatedData = (LookUpSearchMode, Id, selectedLookup = null) => {
    let CorelationDataforTable = [];
    let dynamicValue =
      selectedLookup != null
        ? selectedLookup + "Value"
        : this.state.LookUpSearchMode + "Value";
    let dynamicId =
      selectedLookup != null
        ? this.state.LookUpSearchMode + selectedLookup + "Id"
        : this.state.LookUpSearchMode + "CorrelationId";
    let DynamicId2 =
    selectedLookup != null
      ? selectedLookup + "Id2"
      : this.state.LookUpSearchMode + "Id2";
    GetCorrelationData(LookUpSearchMode, Id).then((response) => {
      if (response.success) {
        const newList =
          response.items.CorrelatedLookupData != null
            ? response.items.CorrelatedLookupData.map((item) => {
                const newItem = {
                  value: item.CorrelationValue,
                  label: item[dynamicValue],
                  Id: item[dynamicId],
                  Id2: item[DynamicId2],
                  isEnable: 1,
                };
                return newItem;
              })
            : "";
        this.setState({
          CorrelationTableData: newList,
          CorrelationValuePk: Id,
          lookupTypeValue: LookUpSearchMode,
          lookupTypePk: Id,
          SelectedCorrelationTypeValue: dynamicValue,
          SelectedCorrelationIdValue: dynamicId,
        });
        CorelationDataforTable = newList;
      }
    });

    var resItem =
      selectedLookup == null ? this.state.LookUpSearchMode : selectedLookup;
    resItem = resItem == "Role" ? "Profile" : resItem;
    GetLookupDataByType(resItem).then((response) => {
      if (response.success) {
        const newList = response.items.map((item) => {
          const newItem = {
            Id: item[resItem + "Id"],
            Title: item[resItem + "Value"],
          };
          return newItem;
        });
        let datafilter = newList;
        CorelationDataforTable.map((itemTop) => {
          datafilter = datafilter.filter(function (value) {
            return value.Id !== itemTop.Id2;
          });
        });
        this.setState({ DataForCorrelationDataList: datafilter });
      }
    });
  };

  onFormSelectChange = (e) => {
    const { labelsFilterData, LookupTypeCorrelationDropDownData } = this.state;
    let index = e.nativeEvent.target.selectedIndex;
    let lookupType = e.nativeEvent.target[index].text;
    if (lookupType == "Role") {
      lookupType = "Profile";
    }
    const { name, value, type } = e.target;
    this.GetlookUpTypes(lookupType);
    this.setState({
      LookupData: {
        ...this.state.LookupData,
        [name]: {
          value: value,
          label: lookupType,
        },
      },
      SearchByText: "Search " + lookupType,
      SearchByTextValue: "",
      lookupTypeValue: lookupType
    });
  };
  lookupTolookupSelectChange = (e, value) => {
    let {
      LookUpSearchMode,
      LookupTypeCorrelationDropDownData,
      CorrelationValuePk,
    } = this.state;
    let index = e.nativeEvent.target.selectedIndex;
    let corrTitle = e.nativeEvent.target[index].text;
    var labelIndex = corrTitle.indexOf(" ");
    var labelIndex2 = corrTitle.indexOf("for") + 4;
    var correlationTitle = corrTitle.substr(0, labelIndex);
    correlationTitle =
      correlationTitle == "Roles" ? "Profiles" : correlationTitle;
    let ChopedCorTitle2 = corrTitle.substr(
      labelIndex2,
      correlationTitle.length
    );
    let ChopedCorTitle = correlationTitle.substring(
      0,
      correlationTitle.length - 1
    );
    ChopedCorTitle = ChopedCorTitle == "Role" ? "Profile" : ChopedCorTitle;
    LookUpSearchMode =
      LookUpSearchMode == "Roles" ? "Profiles" : LookUpSearchMode;
    if (ChopedCorTitle == LookUpSearchMode) {
      correlationTitle = LookUpSearchMode;
    } else {
      correlationTitle = LookUpSearchMode + correlationTitle;
    }
    this.CorrelationTitleSetup(ChopedCorTitle + " Title");
    if (correlationTitle == ChopedCorTitle2) {
      // correlationTitle= null;
      ChopedCorTitle = null;
    }
    this.setState({
       ChopedCorTitle
    });
    this.getCorrelatedData(
      correlationTitle,
      CorrelationValuePk,
      ChopedCorTitle
    );
  };
  setLookupToLookupCoorelation = (lookupType) => {
    const { LookupTypeCorrelationDropDownData } = this.state;
    var newList = LookupTypeCorrelationDropDownData[lookupType];
    var labelIndex = newList[0].label.indexOf(" ");
    var correlationTitle = newList[0].label.substr(0, labelIndex) + " Title";
    this.CorrelationTitleSetup(correlationTitle);
    this.setState({
      lookupCorrelationDropDownSelected: newList,
      selectedLookupToLookup: newList[0],
    });
  };
  CorrelationTitleSetup = (correlationTitle) => {
    correlationTitle =
      correlationTitle == "Profile Title" ? "Role Title" : correlationTitle;
    this.setState({
      selectedLookupToLookupTitle: correlationTitle,
    });
  };
  handleFormFieldChange = (e, typeOf, kkkk, inputType) => {
    const { name, value, type } = e.target;
    this.setState({
      [name]: value,
    });
    var data = this.state.LookupTypesData;

    var results = [];
    var res1 = data.map((item) => {
      var title = item.Title.toLowerCase();
      var entry = value.toLowerCase();
      var match = title.indexOf(entry);
      if (match >= 0) {
        results.push(item);
      }
    });
    this.setState({
      lookuptypesdataFilter: results,
    });
  };
  onCorrelationValueChange = (e, corelationValue) => {
    let { CorrelationTableData } = this.state;
    let itemsList = [];
    itemsList = CorrelationTableData.map((item) => {
      if (corelationValue.Id == item.Id) {
        item.value = e.target.value;
      }
      return item == corelationValue ? corelationValue : item;
    });
    this.setState({
      CorrelationUpdatedValue: {
        value: e.target.value,
        Id: corelationValue.Id,
      },
      CorrelationTableData: itemsList,
    });
  };
  onCorrelationValueBlur = (e, corelationValue) => {
    this.props.toggleLoadingAction(true);
    let { lookupTypeValue, CorrelationTableData, lookupTypePk } = this.state;
    let listItems = [];
    listItems = CorrelationTableData.map((item) => {
      item.isEnable = true;
      return item;
    });
    UpdateLookupCorrelatedDataItem(
      lookupTypeValue,
      lookupTypePk,
      corelationValue.Id,
      corelationValue.value
    )
      .then((res) => {
        const { items, success } = res;
        if (success) {
          const info = {
            message: this.props.labels.ADMIN_CORRELATION_UPDATE_SUCCESS,
            status: "success",
          };
          this.props.toggleLoadingAction(false);
          this.props.setNotificationAction(info);
          this.setState({
            systemLbelData: [],
            CorrelationTableData: listItems,
          });
        } else {
          const info = {
            message: this.props.labels.ADMIN_CORRELATION_UPDATE_FAIL,
            status: "error",
          };
          this.props.toggleLoadingAction(false);
          this.props.setNotificationAction(info);
        }
      })
      .catch((err) => console.log("Err ", err));
  };
  lookupTypeCorrelationSearchChange = (e) => {
    const { name, value, type } = e.target;
    this.setState({
      [name]: value,
    });
    var data = this.state.DataForCorrelationDataList;
    const results = data.filter(item=> item.Title.toLowerCase().includes(value.toLowerCase()));
    
    this.setState({
      DataForCorrelationDataListFilter: results,
    });
  };

  handleFormFieldBlur = (e, { param }) => {
    let {
      SystemLabelsListitems,
      SystemLabelsList,
      systemLabelItem,
    } = this.state;
    if (systemLabelItem != null) {
      this.setState({ loadingId: param.SystemLabelId });
      SystemLabelsListitems = this.state.SystemLabelsList.map((item) => {
        if (param.SystemLabelId == item.SystemLabelId) {
          updateSystemLabel(systemLabelItem)
            .then((res) => {
              const { items, success } = res;
              if (success) {
                const info = {
                  message: this.props.labels.ADMIN_CORRELATION_UPDATE_SUCCESS,
                  status: "success",
                };
                this.props.setNotificationAction(info);
                this.setState({
                  systemLabelItem: null,
                  loadingId: null,
                });
              } else {
                const info = {
                  message: this.props.labels.ADMIN_CORRELATION_UPDATE_FAIL,
                  status: "error",
                };
                this.props.setNotificationAction(info);
              }
            })
            .catch((err) => console.log("Err ", err));
        }
        return item == param ? param : item;
      });
    }
  };

  LookupListItemClick = (value) => {
    const { LookUpSearchMode, lookupTypeValue, lookuptypesdataFilter, ChopedCorTitle } = this.state;
    lookupTypeValue? this.getCorrelatedData(lookupTypeValue, value.Id, ChopedCorTitle): this.getCorrelatedData(LookUpSearchMode, value.Id);
    let listItems = [];
    listItems = lookuptypesdataFilter.map((item) => {
      if ((item.Id != value.Id) & (item.isActive == true)) {
        item.isActive = false;
      }
      if (item.Id == value.Id) {
        item.isActive = true;
      }
      return item;
    });
    this.setState({
      lookuptypesdataFilter: listItems,
    });
  };
  addCorrelation = (value) => {
    this.props.toggleLoadingAction(true);
    const {
      LookUpSearchMode,
      SelectedCorrelationTypeValue,
      SelectedCorrelationIdValue,
      CorrelationValuePk,
      DataForCorrelationDataListFilter
    } = this.state;
    AddCorrelationData(LookUpSearchMode, CorrelationValuePk, value.Id)
      .then((response) => {
        if (response.success && response.items) {
          let filteredArray = DataForCorrelationDataListFilter.filter(item => item.Id !== value.Id)
          const newList =
            response.items.CorrelatedLookupData != null
              ? response.items.CorrelatedLookupData.map((item) => {
                  const newItem = {
                    value: item.CorrelationValue,
                    label: item[SelectedCorrelationTypeValue],
                    Id: item[SelectedCorrelationIdValue],
                    isEnable: 1,
                  };
                  return newItem;
                })
              : "";
          const info = {
            message: this.props.labels.ADMIN_CORRELATION_ADD_SUCCESS,
            status: "success",
          };
          this.props.toggleLoadingAction(false);
          this.props.setNotificationAction(info);
          this.setState({
            CorrelationTableData: newList,
            DataForCorrelationDataListFilter:filteredArray
          });
        } else {
          const info = {
            message: this.props.labels.ADMIN_CORRELATION_ADD_FAIL,
            status: "error",
          };
          this.props.toggleLoadingAction(false);
          this.props.setNotificationAction(info);
        }
      })
      .catch((err) => console.log("Err ", err));
  };

  handelCorrelationDoubleClick = (data) => {
    const { CorrelationTableData } = this.state;
    let listItems = [];
    listItems = CorrelationTableData.map((item) => {
      if ((item.Id != data.Id) & (item.isEnable == false)) {
        item.isEnable = true;
      }
      if (item.Id == data.Id) {
        item.isEnable = false;
      }
      return item;
    });
    this.setState({
      CorrelationTableData: listItems,
    });
  };

  handleDeleteCorrelation = (data) => {
    this.props.toggleLoadingAction(true);
    let {
      DataForCorrelationDataListFilter,
      LookUpSearchMode,
      SelectedCorrelationTypeValue,
      SelectedCorrelationIdValue,
      CorrelationValuePk,
    } = this.state;
    let listItems = [];

    DeleteLookupCorrelatedDataItem(
      CorrelationValuePk,
      LookUpSearchMode,
      data.Id
    )
      .then((response) => {
        if (response.success) {
          const newList =
            response.items.CorrelatedLookupData != null
              ? response.items.CorrelatedLookupData.map((item) => {
                  const newItem = {
                    value: item.CorrelationValue,
                    label: item[SelectedCorrelationTypeValue],
                    Id: item[SelectedCorrelationIdValue],
                    isEnable: 1,
                  };
                  return newItem;
                })
              : "";
          const info = {
            message: this.props.labels.ADMIN_CORRELATION_DELETE_SUCCESS,
            status: "success",
          };
          this.props.toggleLoadingAction(false);
          this.props.setNotificationAction(info);
          this.setState({
            CorrelationTableData: newList,
          });
          DataForCorrelationDataListFilter.push({
            Id: data.value,
            Title: data.label,
          });
        } else {
          const info = {
            message: this.props.labels.ADMIN_CORRELATION_DELETE_FAIL,
            status: "error",
          };
          this.props.toggleLoadingAction(false);
          this.props.setNotificationAction(info);
        }
      })
      .catch((err) => console.log("Err ", err));
  };

  render() {
    const { labels } = this.props;
    const {
      isHelpActive,
      isCollapsed1,
      isCollapsed2,
      isCollapsed3,
      searchKey,
      workplaceList,
      labelsFilterData,
      LookupData,
      isQuick,
    } = this.state;
    return (
      <div className="lookup-correlation-wraper columns-wrapper">
        <Column collapse={isCollapsed1} className="col-1">
          <Column.Collapsed
            text={labels.ADMIN_LOOKUP_CORRELATIONS}
            onClick={() => this.setState({ isCollapsed1: false })}
            tooltipButton={labels.ADMIN_EXPAND}
            isHelpActive={isHelpActive}
            tooltipHelp={labels.ADMIN_EXPAND_BTN}
            tooltipPlace="left"
            testId={"column-1-expand"}
          />
          <Column.Head>
            <div className="heading">
              <p>{labels.ADMIN_LOOKUP_CORRELATIONS}</p>
            </div>
            <Button
              onClick={() => this.setState({ isCollapsed1: true })}
              className="collapseBtn"
              tooltipButton={labels.ADMIN_COLLAPSE}
              tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId={"column-1-collapse"}
            />
          </Column.Head>
          <Column.Body>
            <LookupCorrelationsList
              onFormFieldBlur={this.handleFormFieldBlur}
              onFormSelectChange={this.onFormSelectChange}
              onFormFieldChange={this.handleFormFieldChange}
              LookupListItemClick={this.LookupListItemClick}
              labelsFilter={labelsFilterData}
              LookupData={LookupData}
              searchModeValue={this.state.searchModeValue}
              lookuptypesdataFilter={this.state.lookuptypesdataFilter}
              LookupTypeList={this.state.LookupType}
              LookUpSearchMode={this.state.LookUpSearchMode}
              selectedItem={this.state.selectedItem}
              SearchByText={this.state.SearchByText}
              SearchByTextValue={this.state.SearchByTextValue}
              selectedLookupToLookup={this.state.selectedLookupToLookup}
              labels={this.props.labels}
            />
          </Column.Body>
        </Column>
        <Column collapse={isCollapsed2} className="col-1">
          <Column.Collapsed
            text={labels.ADMIN_LOOKUP_CORRELATIONS_DATA}
            onClick={() => this.setState({ isCollapsed2: false })}
            tooltipButton={labels.ADMIN_EXPAND}
            isHelpActive={isHelpActive}
            tooltipHelp={labels.ADMIN_EXPAND_BTN}
            tooltipPlace="left"
            testId={"column-2-expand"}
          />
          <Column.Head>
            <div className="heading">
              <p>{labels.ADMIN_LOOKUP_CORRELATIONS_DATA}</p>
            </div>
            <Button
              className="button-primary AddBtn"
              testId={"open_correlation_form-btn"}
              tooltipButton={labels.ADMIN_OPEN_LOOKUP_CORRELATION_FORM}
              tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
              onClick={() => this.setState({ isCollapsed3: false })}
            />
            <Button
              onClick={() => this.setState({ isCollapsed2: true })}
              className="collapseBtn"
              tooltipButton={labels.ADMIN_COLLAPSE}
              tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId={"column-2-collapse"}
            />
          </Column.Head>
          <Column.Body>
            <LookupCorrelationDetails
              onFormFieldBlur={this.handleFormFieldBlur}
              onCorrelationValueChange={this.onCorrelationValueChange}
              onCorrelationValueBlur={this.onCorrelationValueBlur}
              handelCorrelationDoubleClick={this.handelCorrelationDoubleClick}
              loadModule={this.componentDidMount}
              onFormSelectChange={this.onFormSelectChange}
              onFormFieldChange={this.handleFormFieldChange}
              lookupTolookupSelectChange={this.lookupTolookupSelectChange}
              handleDeleteCorrelation={this.handleDeleteCorrelation}
              labelsFilter={labelsFilterData}
              LookupData={LookupData}
              searchModeValue={this.state.searchModeValue}
              lookuptypesdataFilter={this.state.lookuptypesdataFilter}
              LookupTypeList={this.state.LookupType}
              LookUpSearchMode={this.state.LookUpSearchMode}
              lookupCorrelationDropDownSelected={
                this.state.lookupCorrelationDropDownSelected
              }
              selectedItem={this.state.selectedItem}
              SearchByText={this.state.SearchByText}
              SearchByTextValue={this.state.SearchByTextValue}
              selectedLookupToLookupTitle={
                this.state.selectedLookupToLookupTitle
              }
              CorrelationTableData={this.state.CorrelationTableData}
              CorrelationValuePk={this.state.CorrelationValuePk}
              labels={this.props.labels}
            />
          </Column.Body>
        </Column>
        <Column collapse={isCollapsed3} className="col-1">
          <Column.Collapsed
            text={labels.ADMIN_ADD_LOOKUP_CORRELATIONS}
            onClick={() => this.setState({ isCollapsed3: false })}
            tooltipButton={labels.ADMIN_EXPAND}
            isHelpActive={isHelpActive}
            tooltipHelp={labels.ADMIN_EXPAND_BTN}
            tooltipPlace="left"
            testId={"column-3-expand"}
          />
          <Column.Head>
            <div className="heading">
              <p>{labels.ADMIN_ADD_LOOKUP_CORRELATIONS}</p>
            </div>
            <Button
              onClick={() => this.setState({ isCollapsed3: true })}
              className="collapseBtn"
              tooltipButton={labels.ADMIN_COLLAPSE}
              tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId={"column-3-collapse"}
            />
          </Column.Head>
          <Column.Body>
            <LookupCorrelationDataList
              lookupTypeValueForCorrelationData={
                this.state.lookupTypeValueForCorrelationData
              }
              addCorrelation={this.addCorrelation}
              lookupTypeCorrelationSearchChange={
                this.lookupTypeCorrelationSearchChange
              }
              LookupTypeCorelationSearchValue={
                this.state.LookupTypeCorelationSearchValue
              }
              DataForCorrelationDataListFilter={
                this.state.DataForCorrelationDataListFilter
              }
              labels={this.props.labels}
            />
          </Column.Body>
        </Column>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user,
    isLoading: state.isLoading,
    labels: state.labels,
  };
}

const actions = {
  setNotificationAction,
  toggleLoadingAction,
};

export default connect(mapStateToProps, actions)(withRouter(LookupCorrelation));
