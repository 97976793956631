import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import DatePicker from "../../../components/DatePicker/DatePicker";
import { withRouter } from "react-router-dom";
import Chart from "react-apexcharts";
import "./report.scss";

import {
  getTotalIpro,
  getTotalSearcher,
  getExpenseLogs,
  getSearches,
} from "../../../Services/apiCalls";
import UserDetails from "../../Statistics/components/UserDetails";
import UsersList from "../../Statistics/components/UsersList";
import { Button, Column, Select } from "../../../components";
import {
  setNotificationAction,
  toggleLoadingAction,
} from "../../../Redux/actions";
import SearchesList from "./components/searchesList";
import SelectedSearches from "./components/selectedSearches";

class SearchesReport extends Component {
  state = {
    StartDate: moment()
      .subtract(30, "days")
      .format("YYYY-MM-DDTHH:mm:ss"),
    EndDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
    isCollapsed1: false,
    isCollapsed2: false,
    selectedUser: null,
  };

  componentDidMount = () => {
    this.onSubmit();
  };

  handleUserClick = (user) => {
    this.setState({
      selectedUser: user,
    });
  };

  handleStartDateChange = (date) => {
    this.setState(
      {
        invalidStartDate: false,
        StartDate: moment(date).format("YYYY-MM-DDTHH:mm:ss"),
      },
      () => {
        this.onSubmit();
      }
    );
  };

  handleEndDateChange = (date) => {
    this.setState(
      {
        invalidEndDate: false,
        EndDate: moment(date).format("YYYY-MM-DDTHH:mm:ss"),
      },
      () => {
        this.onSubmit();
      }
    );
  };

  onSubmit = () => {
    this.props.toggleLoadingAction(true);
    const { StartDate, EndDate } = this.state;

    getSearches(StartDate, EndDate)
      .then((data) => {
        this.setState((st) => ({ ...st, data: data?.items }));
        this.props.toggleLoadingAction(false);
      })
      .catch((err) => {
        this.setState((st) => ({ ...st, data: [] }));
        this.props.toggleLoadingAction(false);
      });
  };

  render() {
    const { labels } = this.props;
    const {
      StartDate,
      EndDate,
      selectedUser,
      isCollapsed1,
      isCollapsed2,
      data,
    } = this.state;
    return (
      <div className="main-div-reports columns-wrapper">
        <Column collapse={isCollapsed1}>
          <Column.Collapsed
            text={"Searches"}
            onClick={() => this.setState({ isCollapsed1: false })}
            tooltipButton={labels.ADMIN_EXPAND}
            tooltipHelp={labels.ADMIN_EXPAND_BTN}
            tooltipPlace="left"
            testId={"column-1-expand"}
          />
          <Column.Head>
            <div className="heading">
              <p>{"Searches"}</p>
            </div>
            <Button
              onClick={() => this.setState({ isCollapsed1: true })}
              className="collapseBtn"
              tooltipButton={labels.ADMIN_COLLAPSE}
              tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
              tooltipPlace="left"
              // isHelpActive={isHelpActive}
              testId={"column-1-collapse"}
            />
          </Column.Head>

          <Column.Body>
            <div className="fields-row">
              <div className="labelCalender">
                <label className="form-label">{"Start Date"}:</label>
                <label className="datepicker-input datepicker-wrapper">
                  <DatePicker
                    name="StartDate"
                    dateFormat="DD-MM-YYYY"
                    selected={StartDate && moment(StartDate)}
                    onChange={this.handleStartDateChange}
                    todayButton="Today"
                    placeholderText={"DD-MM-YYYY"}
                    maxDate={EndDate && moment(EndDate)}
                    autoComplete="off"
                  />
                </label>
              </div>
              <div className="labelCalender">
                <label className="form-label">{"End Date"}:</label>
                <label className="datepicker-input datepicker-wrapper">
                  <DatePicker
                    name="EndDate"
                    dateFormat="DD-MM-YYYY"
                    selected={EndDate && moment(EndDate)}
                    onChange={this.handleEndDateChange}
                    todayButton="Today"
                    minDate={StartDate && moment(StartDate)}
                    maxDate={moment()}
                    placeholderText={"DD-MM-YYYY"}
                    autoComplete="off"
                  />
                </label>
              </div>
            </div>
            <React.Fragment>
              <p className="statisticsText">
                Number of searches: {data?.length || ""}
              </p>
            </React.Fragment>
            <React.Fragment>
              {data?.length > 0 ? (
                <SearchesList
                  UserList={data}
                  handleUserClick={this.handleUserClick}
                  selectedUser={selectedUser}
                />
              ) : (
                <div className="no-item-label">
                  <label>{labels.ADMIN_NO_ITEM_TO_DISPLAY}</label>
                </div>
              )}
            </React.Fragment>
          </Column.Body>
        </Column>
        <Column collapse={isCollapsed2}>
          <Column.Collapsed
            text={"Searches Details"}
            onClick={() => this.setState({ isCollapsed2: false })}
            tooltipButton={labels.ADMIN_EXPAND}
            // isHelpActive={isHelpActive}
            tooltipHelp={labels.ADMIN_EXPAND_BTN}
            tooltipPlace="left"
            testId={"column-1-expand"}
          />
          <Column.Head>
            <div className="heading">
              <p>{"Searches Details"}</p>
            </div>
            <Button
              onClick={() => this.setState({ isCollapsed2: true })}
              className="collapseBtn"
              tooltipButton={labels.ADMIN_COLLAPSE}
              tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
              tooltipPlace="left"
              // isHelpActive={isHelpActive}
              testId={"column-1-collapse"}
            />
          </Column.Head>
          <Column.Body>
            <React.Fragment>
              {selectedUser !== null && (
                <SelectedSearches selectedUser={selectedUser} />
              )}
            </React.Fragment>
          </Column.Body>
        </Column>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    labels: state.labels,
  };
}

const actions = {
  toggleLoadingAction,
};

export default connect(mapStateToProps, actions)(withRouter(SearchesReport));
