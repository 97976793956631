import axios from "axios";
import { ApiUrls, ProdooApiUrls } from "./apiUrls";

export const addUserApi = (user) => {
  return axios
    .post(ApiUrls.Account.SIGNUP, user)
    .then((res) => res)
    .catch(({ response }) => response);
};

export const getAlSystemLabels = () => {
  return axios
    .get(ApiUrls.SystemLabel.systemLabel)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getUserFeatures = ({ featureTypeId, appTypeId }) => {
  return axios
    .get(ApiUrls.SideNavigation.GetUserFeatures({ featureTypeId, appTypeId }))
    .then(({ data }) => data)
    .catch((response) => response);
};

//Module
export const getModules = () => {
  return axios
    .get(ApiUrls.Module.GetModules)
    .then(({ data }) => data)
    .catch((response) => response);
};
//feature area
export const getFeatureAreasApi = () => {
  return axios
    .get(ApiUrls.FeaturesAreas.GetFeaturesAreas)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getAppTypesApi = () => {
  return axios
    .get(ApiUrls.AppTypes.GetAppTypes)
    .then(({ data }) => data)
    .catch((response) => response);
};
//Feature

export const getAllFeatures = () => {
  return axios
    .get(ApiUrls.Features.GETALL)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getFeatures = (appType, featureType, userId) => {
  return axios
    .get(ApiUrls.Features.GetFeatures(appType, featureType, userId))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getFeaturesByModule = (moduleId, appTypeId) => {
  return axios
    .get(ApiUrls.Features.GetFeaturesByModule(moduleId, appTypeId))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const addFeatureApi = (feature) => {
  return axios
    .post(ApiUrls.Features.POST, feature)
    .then((res) => res)
    .catch(({ response }) => response);
};

export const updateFeatureApi = (feature) => {
  return axios
    .put(ApiUrls.Features.PUT(feature.Id), feature)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const UpdateRoleFeature = (feature) => {
  return axios
    .put(ApiUrls.Features.UpdateRoleFeature, feature)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const updateUserFeatureApi = (feature) => {
  return axios
    .put(ApiUrls.Features.updateUserFeature(), feature)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const updateMultiUserFeaturesApi = (features) => {
  return axios
    .put(ApiUrls.Features.updateMultiUserFeatures(), features)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const deleteFeatureApi = (feature) => {
  return axios
    .delete(ApiUrls.Features.Delete(feature.Id))
    .then(({ data }) => data)
    .catch((response) => response);
};

//SubModule
export const getSubModules = () => {
  return axios
    .get(ApiUrls.SubModule.GetSubModules)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const addSubModuleApi = (subModule) => {
  return axios
    .post(ApiUrls.SubModule.POST, subModule)
    .then((res) => res)
    .catch(({ response }) => response);
};

export const updateSubModuleApi = (subModule) => {
  return axios
    .put(ApiUrls.SubModule.UPDATE, subModule)
    .then((res) => res)
    .catch(({ response }) => response);
};

export const deleteSubModuleApi = (id) => {
  return axios
    .get(ApiUrls.SubModule.DELETE(id))
    .then(({ data }) => data)
    .catch((response) => response);
};

//SubFeatures
export const getSubFeatures = (subModuleId) => {
  return axios
    .get(ApiUrls.SubFeature.GetSubFeatures(subModuleId))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const addSubFeatureApi = (subFeature) => {
  return axios
    .post(ApiUrls.SubFeature.POST, subFeature)
    .then((res) => res)
    .catch(({ response }) => response);
};

export const updateSubFeatureApi = (subFeature) => {
  return axios
    .put(ApiUrls.SubFeature.UPDATE, subFeature)
    .then((res) => res)
    .catch(({ response }) => response);
};

export const deleteSubFeatureApi = (id) => {
  return axios
    .get(ApiUrls.SubModule.DeleteSubFeature(id))
    .then(({ data }) => data)
    .catch((response) => response);
};

// Workplace Api's

export const getAdminAllowedFeaturesApi = () => {
  return axios
    .get(ApiUrls.Account.ADMIN_ALLOWED_FEATURE)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getAllWorkplaceApi = () => {
  return axios
    .get(ApiUrls.Workplace.GETALL)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getWorkplaceApi = (id) => {
  return axios
    .get(() => ApiUrls.Workplace.GET(id))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const addWorkplaceApi = (workplace) => {
  return axios
    .post(ApiUrls.Workplace.POST, workplace)
    .then(({ data }) => data)
    .catch((response) => console.log("Response ", response));
};

export const deleteWorkplaceApi = (id) => {
  return axios
    .delete(ApiUrls.Workplace.DELETE(id))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const updateWorkplaceApi = (workplace) => {
  return axios
    .put(ApiUrls.Workplace.POST, workplace)
    .then(({ data }) => data)
    .catch((response) => response);
};

///////////

// Workplace Contact Api's
export const getAllWorkplaceContactApi = () => {
  return axios
    .get(ApiUrls.WorkplaceContacts.GETALL)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const getWorkplaceContactApi = (id) => {
  return axios
    .get(() => ApiUrls.WorkplaceContacts.GET(id))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const addWorkplaceContactApi = (workplaceContact) => {
  return axios
    .post(ApiUrls.WorkplaceContacts.POST, workplaceContact)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const deleteWorkplaceContactApi = (id) => {
  return axios
    .delete(ApiUrls.WorkplaceContacts.DELETE(id))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const updateWorkplaceContactApi = (workplaceContact) => {
  return axios
    .put(ApiUrls.WorkplaceContacts.POST, workplaceContact)
    .then(({ data }) => data)
    .catch((response) => response);
};
//////

// Counties Api's
export const getAllCountriesApi = () => {
  return axios
    .get(ApiUrls.Countries.GETALL)
    .then(({ data }) => data)
    .catch((response) => response);
};

////
export const getWorkplaceSubscriptionConfigurationApi = (id) => {
  return axios
    .get(ApiUrls.WorkplaceSubscriptionConfiguration.GET(id))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const updateConfigurationValueApi = (id, value, wpId, subConfigId) => {
  return axios
    .put(
      ApiUrls.WorkplaceSubscriptionConfiguration.Update(
        id,
        value,
        wpId,
        subConfigId
      )
    )
    .then(({ data }) => data)
    .catch((response) => response);
};

export const addConfigurationValueApi = (item) => {
  return axios
    .post(ApiUrls.WorkplaceSubscriptionConfiguration.POST, item)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const deleteWorkplaceSubscriptionAndConfiguration = (id) => {
  return axios
    .delete(ApiUrls.WorkplaceSubscriptionConfiguration.Delete(id))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const createDatabaseApi = (dbName, isQuick, wpSubId) => {
  return axios
    .get(ApiUrls.Scripts.CREATEDB(dbName, isQuick, wpSubId))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const fillDatabaseApi = (dbName, isQuick) => {
  return axios
    .get(ApiUrls.Scripts.FILLDB(dbName, isQuick))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const cloneProdooCore = (domainName) => {
  return axios
    .get(ApiUrls.Scripts.CLONE_CORE(domainName))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const cloneProdooWeb = (domainName) => {
  return axios
    .get(ApiUrls.Scripts.CLONE_WEB(domainName))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const cloneProdooAdmin = (domainName) => {
  return axios
    .get(ApiUrls.Scripts.CLONE_ADMIN(domainName))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const createProdooWebsite = (domainName) => {
  return axios
    .get(ApiUrls.Scripts.CREATE_PRODOO_WEB(domainName))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const createProdooWebApi = (domainName) => {
  return axios
    .get(ApiUrls.Scripts.CREATE_PRODOO_WEBAPI(domainName))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const createProdooAdminWebsite = (domainName) => {
  return axios
    .get(ApiUrls.Scripts.CREATE_PRODOO_ADMIN(domainName))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const createProdooAdminWebApi = (domainName) => {
  return axios
    .get(ApiUrls.Scripts.CREATE_PRODOO_ADMIN_WEBAPI(domainName))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const createAdminWebApi = (email, domainName) => {
  return axios
    .get(ApiUrls.Scripts.CREATE_ADMIN(email, domainName))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const isDomainAvailableApi = (domainName) => {
  return axios
    .get(ApiUrls.Scripts.IS_DOMAIN_AVAILABLE(domainName))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const upgradeWebsitesApi = (domainName) => {
  return axios
    .get(ApiUrls.Scripts.UPGRADE_WEBSITES(domainName))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const stopWebsitesApi = (domainName, wpSubId) => {
  return axios
    .get(ApiUrls.Scripts.STOP_WEBSITES(domainName, wpSubId))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const resumeWebsitesApi = (domainName, wpSubId) => {
  return axios
    .get(ApiUrls.Scripts.RESUME_WEBSITES(domainName, wpSubId))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const deallocateResoucesApi = (domainName, wpSubId, type) => {
  return axios
    .get(ApiUrls.Scripts.DEALLOCATE_RESOURCES(domainName, wpSubId, type))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const tokenConfirmationApi = (token) => {
  return axios
    .post(ApiUrls.Account.VERIFICATION(token))
    .then(({ data }) => data)
    .catch((response) => response);
};

//Prodoo Api Calls

export const addNewlyCreatedSiteInProdooApi = (item) => {
  return axios
    .post(ProdooApiUrls.AffiliateSite.POST, item)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getAllLookupsApi = () => {
  return axios
    .get(ApiUrls.Lookups.GETALL)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getAllLanguageApi = () => {
  return axios
    .get(ApiUrls.Languages.GETALL)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getSelectedLookupsApi = (lookupType) => {
  return axios
    .get(ApiUrls.SelectedLookups.GETALL(lookupType))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const GetLookupsByType = (lookupType, ItemName) => {
  return axios
    .get(ApiUrls.AddLookup.GETALL(lookupType, ItemName))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const updateLookupItemApi = (lookupType, id, ItemName, searchKey) => {
  return axios
    .get(ApiUrls.AddLookup.UpdateLookup(lookupType, id, ItemName, searchKey))
    .then(({ data }) => data)
    .catch((response) => response);
};

export const DeleteLookupItemApi = (lookupType, lookupitemId, ItemName) => {
  if (lookupType == "Industrie") {
    lookupType = "Industry";
  }
  if (lookupType == "Countrie") {
    lookupType = "Country";
  }
  return axios
    .get(ApiUrls.DeleteLookup.GETALL(lookupType, lookupitemId, ItemName))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getAllLookupTypeStatusApi = () => {
  return axios
    .get(ApiUrls.LookupTypeStatus.GETALL)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getAllCandidateLookupTypeApi = (lookupType) => {
  return axios
    .get(ApiUrls.LookupCandidateType.GETALL(lookupType))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getAllErrorLogs = (type, startDate, endDate,filterText) =>
  axios
    .get(ApiUrls.errorLogs.getAll(type, startDate, endDate,filterText))
    .then(({ data }) => data);

//  users---
export const getAllUsersApi = () => {
  return axios
    .get(ApiUrls.Users.GetAllUsers)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const GetAllAdmins = () => {
  return axios
    .get(ApiUrls.Users.GetAllAdmins)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const RegisterAdmin = (model) => {
  return axios
    .post(ApiUrls.Users.RegisterAdmin, model)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const UpdateAdmin = (model) => {
  return axios
    .put(ApiUrls.Users.UpdateAdmin, model)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const DeleteUser = (model) => {
  return axios
    .delete(ApiUrls.Users.DeleteUser, { data: model })
    .then(({ data }) => data)
    .catch((response) => response);
};
//Configuration => Version
export const getConfiguration = (releaseTitle) => {
  return axios
    .get(ApiUrls.ConfigurationGet.GETALL(releaseTitle))
    .then(({ data }) => data)
    .catch((response) => response);
};

// Get  roles---
export const getRoles = () => {
  return axios
    .get(ApiUrls.Roles.GetRoles)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const CreateRole = (role) => {
  return axios
    .post(ApiUrls.Roles.CreateRole, role)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const DeleteRole = (roleData) => {
  return axios
    .delete(ApiUrls.Roles.DeleteRole, { data: roleData })
    .then(({ data }) => data)
    .catch((response) => response);
};
export const AddConfigItemApi = (model) => {
  return axios
    .post(ApiUrls.AddConfig.POST, model)
    .then(({ data }) => data)
    .catch((response) => response);
};

// get roles features
export const getRoleFeatures = (roleId) => {
  return axios
    .get(ApiUrls.Features.GetRoleFeatures(roleId))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getForgotPasswordApi = (email) => {
  return axios
    .get(`${ApiUrls.Account.ForgotPassword}?email=${email}&GET`)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const changePasswordApi = (fileData) => {
  return axios
    .post(ApiUrls.Settings.ChangePassword, fileData)
    .then(({ data }) => {
      return data;
    });
};

// Buisness users apis start
// get roles features
export const getDemoUsers = () => {
  return axios
    .get(ApiUrls.BuisnessUsers.GETALL)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const RegisterDemoUser = (model) => {
  return axios
    .post(ApiUrls.BuisnessUsers.POST, model)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const UpdateDemoUser = (model) => {
  return axios
    .put(ApiUrls.BuisnessUsers.PUT, model)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const DeleteDemoUser = (userData) => {
  return axios
    .delete(ApiUrls.BuisnessUsers.Delete, { data: userData })
    .then(({ data }) => data)
    .catch((response) => response);
};
// Buisness users apis end

// Products apis start

export const getAllProducts = () => {
  return axios
    .get(ApiUrls.Products.GETALL)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const addProduct = (model) => {
  return axios
    .post(ApiUrls.Products.POST, model)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const updateProduct = (model) => {
  return axios
    .put(ApiUrls.Products.Put, model)
    .then(({ data }) => data)
    .catch((response) => response);
};

export const DeleteProduct = (model) => {
  return axios
    .delete(ApiUrls.Products.Delete, { data: model })
    .then(({ data }) => data)
    .catch((response) => response);
};

// products apis end

// feedback apis start

export const getAdminFeedbacks = () => {
  return axios
    .get(ApiUrls.AdminFeedback.GETALL)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const ReplyFeedback = (model) => {
  return axios
    .post(ApiUrls.AdminFeedback.REPLY, model)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const DeleteFeedbackAPI = (model) => {
  return axios
    .delete(ApiUrls.AdminFeedback.Delete, { data: model })
    .then(({ data }) => data)
    .catch((response) => response);
};

// events management api
export const getAdminEvents = (dateFrom, dateTo) => {
  return axios
    .get(ApiUrls.Events.GETALL(dateFrom, dateTo))
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getAdminEventsByUserId = (uId, dateFrom, dateTo) => {
  return axios
    .get(ApiUrls.Events.GETEventsByUserId(uId, dateFrom, dateTo))
    .then(({ data }) => data)
    .catch((response) => response);
};

//Statistics Api's
export const getTotalIpro = (startDate, endDate) =>
  axios
    .get(ApiUrls.Statistics.getTotalIpro(startDate, endDate))
    .then(({ data }) => data);

export const getTotalSearcher = (startDate, endDate) =>
  axios
    .get(ApiUrls.Statistics.getTotalSearcher(startDate, endDate))
    .then(({ data }) => data);

export const getExpenseLogs = (startDate, endDate) =>
  axios
    .get(ApiUrls.Statistics.getExpenseLogs(startDate, endDate))
    .then(({ data }) => data);

//Members api call
export const getMembers = () => {
  return axios
    .get(ApiUrls.User.GetMembers)
    .then(({ data }) => data)
    .catch((response) => response);
};
export const getResumesApi = (userResumeId) => {
  return axios
    .get(ApiUrls.User.GetMyResume(userResumeId))
    .then(({ data }) => data);
};

// reports api call
export const getSearches = (startDate, endDate) =>
  axios
    .get(ApiUrls.Reports.getSearhes(startDate, endDate))
    .then(({ data }) => data);

export const getOpportunities = (startDate, endDate) =>
  axios
    .get(ApiUrls.Reports.getOpportunities(startDate, endDate))
    .then(({ data }) => data);
export const getCollaborations = (startDate, endDate) =>
  axios
    .get(ApiUrls.Reports.getCollaboration(startDate, endDate))
    .then(({ data }) => data);
export const getLookUp = (lookup, startDate, endDate) =>
  axios
    .get(ApiUrls.Reports.getLookUp(lookup, startDate, endDate))
    .then(({ data }) => data);


