import React, { Component } from "react";
import { connect } from "react-redux";
import "./UserDetails.scss";

class UserDetails extends Component {
    render() {
        const { labels, selectedUser } = this.props;
        return (
            <div className="user-detail-component">
                {!selectedUser.UserId ? (
                    <React.Fragment>{labels.NO_ITEM_LABEL}</React.Fragment>
                ) : (
                    <div className="MsgWrapper">
                        <div className="companyBigIcon">
                            <div className={`dashItemImg no-dp"}`}>
                                <img
                                    className="roundeImg"
                                    alt=""
                                    src={selectedUser.ProfilePicture}
                                />
                            </div>
                        </div>
                        <div className="titleLabel">{selectedUser.UserFirstName}</div>
                        <div className="feedbackWrap">
                            <div className="msgItemDiv">
                                <div>
                                    <label className="leftLabel">
                                        {labels.TYPE_LABEL}
                                    </label>
                                    <label className="rightLabel">
                                        {selectedUser.IsFreelancer ? labels.IPRO_NAVIGATION_ROLE_LABEL : labels.SEARCHER_NAVIGATION_ROLE_LABEL}
                                    </label>
                                </div>
                                {selectedUser &&
                                    selectedUser.UserEmail && (
                                        <div>
                                            <label className="leftLabel">
                                                {labels.EMAIL_LABEL}
                                            </label>
                                            <label className="rightLabel">
                                                {selectedUser.UserEmail}
                                            </label>
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        labels: state.labels
    };
}
export default connect(mapStateToProps)(UserDetails);
