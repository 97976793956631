import store from "./store";
import { StorageService } from '../Services/storage';
import { auth } from "../Services/auth";
import { saveUserAction, setLabelsAction } from "./actions";
   import Axios from "axios";

export const initialConfig = () => {
    const user = StorageService.getUser();
    const labels = StorageService.getLabels();
    if (labels.length != 0) {
        store.dispatch(setLabelsAction(labels));
    }
    const authObj = new auth();
    authObj.setBaseUrl();
    const options = {
        url: `${window.location.origin}/admin-system-label.json`,
        method: "get",
        headers: {
            ...Axios.defaults.headers.common,
        },
    };

    Axios(options)
        .then((res) => {
            store.dispatch(setLabelsAction(res?.data));
        })
        .catch((err) => {
            console.error(err);
        });
    if (user) {
        authObj.setDefaultAuthHeaders();
        store.dispatch(saveUserAction(user))
    }
    return true;
}