import React, { Component } from "react";
import Routes from "./Routes/routing";
import store from "./Redux/store";
import { Provider } from "react-redux";
import { auth } from "./Services/auth";
import { initialConfig } from "./Redux/initialConfig";
import { StorageService } from "./Services/storage";
import { ErrorBoundary } from "./components";

const authObj = new auth();

class App extends Component {
  constructor() {
    super();
  }
  componentDidMount = () => {
    authObj.setBaseUrl();
    if (StorageService.isAuthenticated()) {
      authObj.setDefaultAuthHeaders();
      initialConfig();
    }
  };
  render() {
    return (
      <ErrorBoundary>
        <Provider store={store}>
          <Routes />
        </Provider>
      </ErrorBoundary>
    );
  }
}

export default App;
