import React, { Component } from "react";
import { List } from "../../../../components";
import "./searchesList.scss";
import moment from "moment";
class SearchesList extends Component {
  render() {
    const { UserList, handleUserClick, selectedUser } = this.props;
    return (
      <div>
        {UserList !== null ? (
          <List className="searches-list">
            {UserList.map((item, index) => (
              <List.ListItem
                // onClick={() => handleUserClick(item)}
                key={index}
                // isSelected={
                //   selectedUser
                //     ? selectedUser.RequestId === item.RequestId
                //     : null
                // }
              >
                <div className="">
                  <div className={"defaultCompanyName"}>{item.SearchValue}</div>
                  <div className="CompAddress">Count: {item?.Count}</div>
                </div>
              </List.ListItem>
            ))}
          </List>
        ) : null}
      </div>
    );
  }
}
export default SearchesList;
