// -------------------------------------------------------------------------------------------------
//-----------------------------------There are two files for template----------------------------
// path1: public-> templates.html
// path2: src-> views-> EmailTemplates-> defaultTemplates.js
//---------------Whenever you change one file, make the change in other file as well-------
//-------------------------------------------------------------------------------------------------

const DefaultTemplate = (body, subject) => {
  return `
  <table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#f3f3f3">
  <tbody style="background:"#fff"">
      <tr>
          <td  valign="top">
              <div style="margin:0 auto;display: block;">
                  <!-- Header -->
                  <div style="background: #6650A3;min-height: 100px;text-align: center;padding-top: 20px">
                      <img src="http://dev.prodoo.com/webapi/EmailImages/logo.png"
                           alt=""
                           style="margin-bottom: 20px;
                       width: 150px;
                       height:50px;
                       " />

                      <div style="background: #fff;border-radius: 10px 10px 0  0;display: block;padding: 10px;margin: -2px 30px;">
                      <h3>${subject ||
                        ""}</h3>
                      </div>
                  </div>
                  <!-- END Header -->
                  <div style="padding:10px 40px;background: #fff;">
                  ${body}
                  <div>
                  <h5>Thanks, </br>Prodoo Support</h5></div>
                  </div>
                  <!-- Footer -->
                  <div style="background:#6650A3;width:100%">
                      <div style="padding: 40px 60px;">
                          <p style="color:#fff;font-family:Roboto,RobotoDraft,Helvetica,Arial,sans-serif;font-size:14px;line-height: 21px;text-align:center;margin: 0;padding: 0;">
                           You can Update your preferences or unsubscribe from this list. If you are no longer interested in receiving emails.
                          <br /><br />
                          Copyright © 2024 Prodoo
                            <br /><br />
                          </p>
                      </div>
                  </div>
               </div>
          </td>
      </tr>
  </tbody>
</table>
        `;
};

export default DefaultTemplate;
