import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import DatePicker from "../../../components/DatePicker/DatePicker";
import { withRouter } from "react-router-dom";
import "../searches/report.scss";

import { getAllLookupsApi, getLookUp } from "../../../Services/apiCalls";
import { Button, Column, Select } from "../../../components";
import { toggleLoadingAction } from "../../../Redux/actions";
import SearchesList from "./components/searchesList";
import SelectedSearches from "./components/selectedSearches";

class LookUpReport extends Component {
  state = {
    StartDate: moment()
      .subtract(30, "days")
      .format("YYYY-MM-DDTHH:mm:ss"),
    EndDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
    isCollapsed1: false,
    isCollapsed2: false,
    selectedUser: null,
    lookUp: [],
  };

  GetAllLookups = () => {
    getAllLookupsApi()
      .then((res) => {
        let newLookupsList = res.items.map((item) => {
          return { ...item, value: item.LookupTypesId, label: item.LookupName };
        });
        this.setState((st) => ({
          ...st,
          lookUp: newLookupsList,
          selectedLookUp: newLookupsList?.[0],
        }));
        this.onSubmit(newLookupsList?.[0]?.LookupTypesId);
      })
      .catch((err) => console.log(err));
  };

  componentDidMount = () => {
    this.GetAllLookups();
    // this.onSubmit();
  };

  handleUserClick = (user) => {
    this.setState((st) => ({ ...st, selectedUser: user }));
  };

  handleStartDateChange = (date) => {
    this.setState(
      {
        invalidStartDate: false,
        StartDate: moment(date).format("YYYY-MM-DDTHH:mm:ss"),
      },
      () => {
        this.onSubmit(this.state?.selectedLookUp?.LookupTypesId);
      }
    );
  };

  handleEndDateChange = (date) => {
    this.setState(
      {
        invalidEndDate: false,
        EndDate: moment(date).format("YYYY-MM-DDTHH:mm:ss"),
      },
      () => {
        this.onSubmit(this.state?.selectedLookUp?.LookupTypesId);
      }
    );
  };

  handleFormSelectChange = (selectedOption) => {
    this.setState((st) => ({ ...st, selectedLookUp: selectedOption }));
    this.onSubmit(selectedOption?.LookupTypesId);
  };

  onSubmit = (lookup) => {
    this.props.toggleLoadingAction(true);
    const { StartDate, EndDate } = this.state;

    getLookUp(lookup, StartDate, EndDate)
      .then((data) => {
        this.setState((st) => ({ ...st, data: data?.items }));
        this.props.toggleLoadingAction(false);
      })
      .catch((err) => {
        this.setState((st) => ({ ...st, data: [] }));
        this.props.toggleLoadingAction(false);
      });
  };

  render() {
    const { labels } = this.props;
    const { StartDate, EndDate, selectedUser, isCollapsed1, data } = this.state;

    return (
      <div className="main-div-reports columns-wrapper">
        <Column collapse={isCollapsed1}>
          <Column.Collapsed
            text={"Lookup"}
            onClick={() => this.setState({ isCollapsed1: false })}
            tooltipButton={labels.ADMIN_EXPAND}
            tooltipHelp={labels.ADMIN_EXPAND_BTN}
            tooltipPlace="left"
            testId={"column-1-expand"}
          />
          <Column.Head>
            <div className="heading">
              <p>{"Lookup"}</p>
            </div>
          </Column.Head>

          <Column.Body>
            <div className="fields-row">
              <div className="labelCalender">
                <label className="form-label">{"Start Date"}:</label>
                <label className="datepicker-input datepicker-wrapper">
                  <DatePicker
                    name="StartDate"
                    dateFormat="DD-MM-YYYY"
                    selected={StartDate && moment(StartDate)}
                    onChange={this.handleStartDateChange}
                    todayButton="Today"
                    placeholderText={"DD-MM-YYYY"}
                    maxDate={EndDate && moment(EndDate)}
                    autoComplete="off"
                  />
                </label>
              </div>
              <div className="labelCalender">
                <label className="form-label">{"End Date"}:</label>
                <label className="datepicker-input datepicker-wrapper">
                  <DatePicker
                    name="EndDate"
                    dateFormat="DD-MM-YYYY"
                    selected={EndDate && moment(EndDate)}
                    onChange={this.handleEndDateChange}
                    todayButton="Today"
                    minDate={StartDate && moment(StartDate)}
                    maxDate={moment()}
                    placeholderText={"DD-MM-YYYY"}
                    autoComplete="off"
                  />
                </label>
              </div>
            </div>
            <React.Fragment>
              <p className="statisticsText">
                Number of searches: {data?.length || ""}
              </p>
            </React.Fragment>
            <React.Fragment>
              <React.Fragment>
                <div className="input-select-field setHeight50p">
                  <h6>{"Lookup Type"}</h6>
                  <Select
                    name="Type"
                    className={`select-input`}
                    placeholder={"Select Type"}
                    value={this.state.selectedLookUp}
                    onChange={(selectedOption) =>
                      this.handleFormSelectChange(selectedOption)
                    }
                    options={this.state.lookUp}
                    clearable={false}
                    searchable={false}
                  />
                </div>
              </React.Fragment>
            </React.Fragment>
            <React.Fragment>
              {data?.length > 0 ? (
                <SearchesList
                  UserList={data}
                  handleUserClick={this.handleUserClick}
                  selectedUser={selectedUser}
                />
              ) : (
                <div className="no-item-label">
                  <label>{labels.ADMIN_NO_ITEM_TO_DISPLAY}</label>
                </div>
              )}
            </React.Fragment>
          </Column.Body>
        </Column>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    labels: state.labels,
  };
}

const actions = {
  toggleLoadingAction,
};

export default connect(mapStateToProps, actions)(withRouter(LookUpReport));
