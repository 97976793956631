import React, { Component } from "react";
import { Column, Button, List, CustomInput } from "../../../components";
import "../styles.scss";
import { getRoles, CreateRole, DeleteRole } from "../../../Services/apiCalls";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import loadingIcon from "../../Assests/Images/loading.gif";
// import doneIcon from "../../Assests/Images/done.png";
// import failedIcon from "../../Assests/Images/failed.png";

import {
  setNotificationAction,
  toggleLoadingAction,
} from "../../../Redux/actions";
// import { validateInputField } from '../../utilities/validator';
// import { isValidEmail } from '../../utilities/validations';
class AdminManagementRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed1: false,
      isCollapsed2: false,
      isCollapsed3: true,
      Roles: [],
      filteredRoles: [],
      adminRoleInput: "",
    };
  }
  componentDidMount = () => {
    this.GetRoles();
  };

  GetRoles = () => {
    getRoles().then((res) => {
      this.setState({
        Roles: res.items,
        filteredRoles: res.items,
      });
    });
  };
  CreateRole = () => {
    const { adminRoleInput } = this.state;
    if (!adminRoleInput) {
      const info = {
        message: this.props.labels.ENTER_ADMIN_ROLE_VALIDATION_LABEL,
        status: "error",
      };
      this.props.setNotificationAction(info);
      return null;
    }
    var role = {
      RoleName: adminRoleInput,
    };
    CreateRole(role).then((res) => {
      if (res.success) {
        this.GetRoles();
      }
    });
  };
  DeleteRole = (selectedItem) => {
    DeleteRole(selectedItem).then((res) => {
      if (res.success) {
        this.GetRoles();
      }
    });
  };

  onFormFieldChange = (e) => {
    const { name, value, type } = e.target;
    var data = this.state.Roles;
    const rolesFilter = data.filter((item) =>
      item.RoleName.toLowerCase().includes(value.toLowerCase())
    );

    this.setState({
      [name]: value,
      filteredRoles: rolesFilter,
    });
  };

  render() {
    const { labels } = this.props;
    const {
      isHelpActive,
      isCollapsed1,
      filteredRoles,
      adminRoleInput,
    } = this.state;
    return (
      <div className="admin-management-role-wraper columns-wrapper">
        <Column collapse={isCollapsed1} className="col-1">
          <Column.Collapsed
            text={labels.ADMIN_ROLE_MANAGEMENT_LABEL}
            onClick={() => this.setState({ isCollapsed1: false })}
            tooltipButton={labels.ADMIN_EXPAND}
            isHelpActive={isHelpActive}
            tooltipHelp={labels.ADMIN_EXPAND_BTN}
            tooltipPlace="left"
            testId={"column-1-expand"}
          />
          <Column.Head>
            <div className="heading">
              <p>{labels.ADMIN_ROLE_MANAGEMENT_LABEL}</p>
            </div>
            <Button
              onClick={() => this.setState({ isCollapsed1: true })}
              className="collapseBtn"
              tooltipButton={labels.ADMIN_COLLAPSE}
              tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId={"column-1-collapse"}
            />
          </Column.Head>
          <Column.Body>
            <div className="admin-management-role-col1">
              <div className="adminRoleInput-field">
                <div
                  className={`${
                    !adminRoleInput ? "inValid " : " "
                  } input-field `}
                >
                  <h6>{labels.ADMIN_ADD_ROLE_LABEL}</h6>
                  <div>
                    <input
                      name={"adminRoleInput"}
                      type={"text"}
                      value={adminRoleInput || ""}
                      onChange={(e) => this.onFormFieldChange(e)}
                      test-data-id={"admin-management-role-input"}
                      placeholder={"Add Role"}
                    />
                  </div>
                </div>
                <Button className="AddBtnRole" onClick={this.CreateRole} />
              </div>
              {filteredRoles && filteredRoles.length > 0 ? (
                <div className="list-wrapper">
                  {filteredRoles.map((item) => (
                    <div
                      test-data-id={`roles-list-item`}
                      className={`list-item`}
                      style={{
                        justifyContent: "space-between",
                      }}
                      key={item.RoleId}
                    >
                      <div className="list_item_title">
                        <span>{item.RoleName}</span>
                      </div>
                      <button
                        test-data-id={`delete-role-btn`}
                        type="button"
                        className="delete-btn"
                        onClick={() => this.DeleteRole(item)}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="no-item-label">
                  <label>{labels.ADMIN_NO_ITEM_TO_DISPLAY}</label>
                </div>
              )}
            </div>
          </Column.Body>
        </Column>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.user,
    isLoading: state.isLoading,
    labels: state.labels,
  };
}

const actions = {
  setNotificationAction,
  toggleLoadingAction,
};

export default connect(
  mapStateToProps,
  actions
)(withRouter(AdminManagementRole));
